import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminBookingDetailsComponent } from './admin-booking-details/admin-booking-details.component';

@Component({
  selector: 'app-admin-booking',
  templateUrl: './admin-booking.component.html',
  styleUrls: ['./admin-booking.component.css']
})
export class AdminBookingComponent implements OnInit {

  contactMin = {
    max: 20,
    page: 1,
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getcontacts();
  }

  /**función para obtener el listado de categorias
  */
  getcontacts(){
    this.session.postRequest("contact:list",this.contactMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
    },error=>{
      console.log("error:contact:list",error);
    })
  }

  /** funcionalidad para crear una categoria
  */
  view(object){
    const dialogRef = this.dialog.open(AdminBookingDetailsComponent, {
      width: '50%',
      data: object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getcontacts();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  delete(object){
    this.loadding.wait();
    this.session.postRequest("contact:delete",object).subscribe((data:any)=>{
      for(let i = 0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log(error);
      this.loadding.hide();
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.contactMin.max = event.pageSize;
    this.contactMin.page = event.pageIndex + 1;
    this.getcontacts();
  }

}
