<div class="row justify-content-center mt-2">
  <div class="col-10">
    <mat-card>
      <input type="file" #fileGet id="fileGet" name="fileGet" style="display:none;" />
      <p class="pb-1">{{title}}</p>
      <div class="border-card" *ngIf="multiple ? true : files.length < 1">
        <div class="row justify-content-center">
          <div class="col-11">

            <button mat-button (click)="getFile();"><mat-icon>add_circle</mat-icon>Agregar imagen</button>

          </div>
        </div>
      </div>
      <div class="row mt-2" *ngFor="let object of files">
        <div class="col-12">
          <mat-progress-bar [mode]="object.mode" [value]="object.valueProcess"></mat-progress-bar>
        </div>
        <div class="col-1">
          <span class="material-icons" style="padding-top: 10px;">description</span>
        </div>
        <div class="col-6">
          <p style="padding-top: 10px;">{{object.name}}</p>
        </div>
        <div class="col-2">
          <!-- <button mat-icon-button color="accent" (click)="downloadAFile(object)" matTooltip="Click para descargar el archivo" *ngIf="object.uuidFile != ''">
            <span class="material-icons">cloud_download</span>
          </button> -->
        </div>
        <div class="col-2">
          <!-- <button mat-icon-button color="warn" (click)="deleteAFile(object)" matTooltip="Click para eliminar el archivo" *ngIf="deleteCode">
            <span class="material-icons">delete_forever</span>
          </button> -->
        </div>
        <div class="col-1">
          <!-- <button mat-icon-button color="accent"  *ngIf="!object.upload && !object.uploaded " (click)="uploadFile(object)" matTooltip="Click para subir el archivo">
            <span class="material-icons">cloud_upload</span>
          </button>
          <mat-icon aria-hidden="false" color="accent" *ngIf="object.uploaded && object.valueProcess >= 100" style="padding-top: 10px; color: #4CAF50;">cloud_done</mat-icon> -->
        </div>
      </div>
    </mat-card>
  </div>
</div>
