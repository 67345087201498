import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';

import { ClientHomeComponent } from './client/client-home/client-home.component';
import { ClientProductComponent } from './client/client-product/client-product.component';
import { ClientWhyHouseMexalliComponent } from './client/client-why-house-mexalli/client-why-house-mexalli.component';
import { ClientGalleryComponent } from './client/client-gallery/client-gallery.component';
import { ClientBookingComponent } from './client/client-booking/client-booking.component';
import { DetailsProductComponent } from './client/client-product/details-product/details-product.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminBannerComponent } from './admin/admin-banner/admin-banner.component';
import { AdminMakerComponent } from './admin/admin-maker/admin-maker.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { ClientShoppingCartComponent } from './client/client-shopping-cart/client-shopping-cart.component';
import { ClientPaymentComponent } from './client/client-payment/client-payment.component';
import { ClientSearchComponent } from './client/client-search/client-search.component';
import { AdminSalesComponent } from './admin/admin-sales/admin-sales.component';
import { AdminBookingComponent } from './admin/admin-booking/admin-booking.component';
import { AdminMescaleroComponent} from './admin/admin-mescalero/admin-mescalero.component';
import { AdminGuidesComponent } from './admin/admin-guides/admin-guides.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';


const routes: Routes = [
  { path: 'client/home', component: ClientHomeComponent},
  { path: '', pathMatch: 'full', redirectTo: 'client/home'},
  { path: 'client/product/:maker', component:ClientProductComponent },
  { path: 'client/product/detail/:id', component:DetailsProductComponent },
  { path: 'client/whyhousemexcalli', component:ClientWhyHouseMexalliComponent},
  { path: 'client/galley', component:ClientGalleryComponent},
  { path: 'client/booking', component:ClientBookingComponent},
  { path: 'client/cart', component:ClientShoppingCartComponent },
  { path: 'client/payment', component:ClientPaymentComponent },
  { path: 'client/search/:phone',component:ClientSearchComponent },

  { path:'admin/login', component: AdminLoginComponent },
  { path:'admin/dashboard', component: AdminDashboardComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']}  },
  { path:'admin/delivery', component: AdminDeliveryComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/category', component: AdminCategoryComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/banner', component: AdminBannerComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/maker', component: AdminMakerComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/product', component: AdminProductComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/product/update/:id', component: AdminProductUpdateComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/sales', component: AdminSalesComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/booking', component: AdminBookingComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/mescalero', component: AdminMescaleroComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/guides', component: AdminGuidesComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/set/home', component: AdminHomeComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash:true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
