import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminBannerUpdateComponent } from './admin-banner-update/admin-banner-update.component';

@Component({
  selector: 'app-admin-banner',
  templateUrl: './admin-banner.component.html',
  styleUrls: ['./admin-banner.component.css']
})
export class AdminBannerComponent implements OnInit {

  bannerMin = {
    max: 10,
    page: 1,
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };
  pageEvent: PageEvent;
  constructor(private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getBanners();
  }

  /**función para obtener el listado de categorias
  */
  getBanners(){
    this.session.postRequest("banner:list",this.bannerMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      for(let i = 0; i < this.list.length; i++){
        this.list[i].image = 'https://casamexcalli.com/public/banners/'+this.list[i].image;
      }
      this.paginator.total = data.object.total;
    },error=>{
      console.log("error:banner:list",error);
    })
  }

  /** funcionalidad para crear una categoria
  */
  updateBanner(uuid){
    const dialogRef = this.dialog.open( AdminBannerUpdateComponent, {
      width: '50%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getBanners();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  deleteBanner(object){
    this.loadding.show(true, "Espere un momento...");
    this.session.postRequest("banner:delete",object).subscribe((data:any)=>{
      for(let i=0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("error:banner:delete",error);
      this.loadding.hide();
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.bannerMin.max = event.pageSize;
    this.bannerMin.page = event.pageIndex + 1;
    this.getBanners();
  }

}
