import { Component, OnInit, Inject } from '@angular/core';
import { SessionService } from './../../service/session/session.service';
import { LoadingService } from './../../directive/loading/loading.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as moment from 'moment';
moment.locale('es-us');

@Component({
  selector: 'app-client-booking',
  templateUrl: './client-booking.component.html',
  styleUrls: ['./client-booking.component.css']
})
export class ClientBookingComponent implements OnInit {
  controlForm: FormGroup;

  contactModel ={
    id:'',
    name:'',
    email:0,
    phone:'',
    comments:'',
    status:true,
    meetdate:''
  }

  metadata = {
    date:moment().toISOString()
  }
  object = JSON.parse(JSON.stringify(this.contactModel));

  constructor(public dialogRef: MatDialogRef<ClientBookingComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public session:SessionService,
  public loadding:LoadingService,
  public fb: FormBuilder,
  public snackBar: MatSnackBar
) {
  this.controlForm = fb.group({
    'name': [null, Validators.compose([Validators.required])],
    'person': [null, Validators.compose([Validators.required])],
    'phone': [null, Validators.compose([Validators.required])],
    'meetdate': [null, Validators.compose([Validators.required])],
  });
}

  ngOnInit(): void {
  }

  send(object){
    if(object.name != '' && object.email != '' && object.phone != ''){
      this.loadding.show(true,"Espere un momento...");
      object.meetdate = moment(this.metadata.date).format('YYYY-MM-DD hh:mm:ss');
      this.session.postRequestAnonimus("contact:update",this.object).subscribe((data:any)=>{
        this.snackBar.open('Gracias. Nosotros nos comunicaremos contigo para confirmar tu reservación.', '', {
          duration: 8000
        });
        this.loadding.hide();
        this.close(true);
      },error=>{
        this.loadding.hide();
        console.log(error);
      })
    }else{
      this.snackBar.open('Verifica los datos que ingresaste por favor.', '', {
        duration: 8000
      });
    }

  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'meeting:001';
    }
    this.dialogRef.close(object);
  }

}
