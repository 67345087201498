import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageTool} from '../../service/session/storage.tool';

export interface MenuModel {
  enabled:string,
}
@Injectable({
  providedIn: 'root'
})

export class ClientMenuService {
  public menuItem:MenuModel = {
    enabled:''
  };
  menuItemEnabled: Subject<MenuModel> = new Subject<MenuModel>();

  constructor(private storage: StorageTool) {}

  setMenuItem(object){
    this.menuItem.enabled = object;
    this.menuItemEnabled.next(this.menuItem);
  }

  getMenuItem(){
    return this.menuItem;
  }

}
