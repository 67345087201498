import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KsfUploadFiles  } from './ksf-upload-files';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  providers:[
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule
  ],
  declarations: [
    KsfUploadFiles
  ],
  entryComponents: [],
  exports: [
    KsfUploadFiles,
  ]
})
export class KsfUploadFilesModule {
  public static forRoot(): ModuleWithProviders <KsfUploadFilesModule> {
    return {
      ngModule: KsfUploadFilesModule,
    };
  }
 }
