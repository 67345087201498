import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from './../../directive/loading/loading.module';

@Component({
  selector: 'app-client-gallery',
  templateUrl: './client-gallery.component.html',
  styleUrls: ['./client-gallery.component.css']
})
export class ClientGalleryComponent implements OnInit {
  bannerModel = {
    id:'1',
    name:'',
    description:'',
    status:true,
    img:'',
    maker:{
      id:''
    }
  }
  object = JSON.parse(JSON.stringify(this.bannerModel));

  productMin = {
    status: true,
    order:'',
    max:20,
    page:1,
    maker:{
      id:''
    }
  };
  list = [];

  metadata ={
    getMoreProducts:true
  }

  constructor(private session: SessionService, public loading: LoadingService, public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {
    this.loading.show(true,"Espere un momento...");
     // cargamos los datos de la BD
     this.session.postRequest('mothmezcalero:get',{id:1}).subscribe((data:any)=>{
       if(data.object){
         this.object = JSON.parse(JSON.stringify(data.object));
         this.object.img = 'https://casamexcalli.com/public/month/'+this.object.img;
         this.productMin.maker.id = this.object.maker.id;
         this.getProducts();
       }
       this.loading.hide();
     },
     (error)=>{
       this.loading.hide();
       console.log('Error: mothmezcalero:get',error)
     })
  }

  getProducts(){
    this.loading.show(true,"Espere un momento...")
    this.session.postRequestAnonimus("product:findAllByMaker",this.productMin).subscribe((data:any)=>{
      for(let i = 0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
      }
      this.list = this.list.concat(data.object.instanceList);
      if(data.object.instanceList.length == 0){
        this.metadata.getMoreProducts = false;
      }else{
        this.metadata.getMoreProducts = true;
      }
      this.loading.hide();
    },error=>{
      console.log("product:findAllByMaker:error",error);
      this.loading.hide();
    })
  }

  /**función para obetner mas productos
  */
  showMoreProducts(){
    if(this.metadata.getMoreProducts){
      this.productMin.page++;
      this.metadata.getMoreProducts = false;
      this.getProducts();
    }
  }

}
