import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { SessionService } from '../../../service/session/session.service';
import { LoadingService } from '../../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageTool } from '../../../service/session/storage.tool';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ClientProductShoopingComponent } from '../client-product-shooping/client-product-shooping.component';

@Component({
  selector: 'app-details-product',
  templateUrl: './details-product.component.html',
  styleUrls: ['./details-product.component.css']
})
export class DetailsProductComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[] = [{
          width: '100%',
          height: '400px',
          thumbnailsColumns: 4,
          imageAnimation: NgxGalleryAnimation.Slide
        },


        {
          breakpoint: 400,
          preview: false
        }];
  galleryImages: NgxGalleryImage[] = [];
  productModel = {
    id:'',
    name:'',
    price:'',
    short_description:'',
    description:'',
    image:'',
    tags:'',
    category_id:'',
    status:true,
    en_name:'',
    en_short_description:'',
    en_description:'',
    availability:1,
    outstanding:false,
    discount:false,
    freeDelivery:false,
    discountValue:0
  };

  object = JSON.parse(JSON.stringify(this.productModel));
  metadata = {
    quantity:1,
    capacity:'',
    productPriceList:[],
    unitPrice:0,
    productPriceSelected:{
      id:''
    },
    unitPriceDiscount:0
  }
  productMin ={
    status: true,
    outstanding:true,
    max:20,
    page:1,
    maker:{
      id:''
    }
  }
  fakeQuantity = [];
  products = [];
  productAttributeList = [];
  // productPriceList = [];
  productLastSee = [];

  constructor(private session: SessionService,
    private router:Router,
    public activatedRoute:ActivatedRoute,
    public snackBar: MatSnackBar,
    public loading:LoadingService,
    private _location: Location,
    public storage: StorageTool,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.galleryImages = [];
    this.fakeQuantity = [];
    this.activatedRoute.params.subscribe((params: Params)=>{
      this.getLastSeeProducts();
      if(params['id'] == 'new'){
      }else{
        this.object.id = params['id'];
        this.loading.show(true,"Espere un momento...");
        this.session.postRequest("product:get",this.object).subscribe((data:any)=>{
          this.object = data.object;
          this.object.image = 'https://casamexcalli.com/public/uploads/'+this.object.image;
          this.productMin.maker.id = this.object.maker_id;
          this.metadata.unitPrice = this.object.price;
          this.object.product_prices = [];
          //verificamos si hay imagenes cargadas
          let imgAux = [];
          for(let item of this.object.images){
            imgAux.push({
              small:'https://casamexcalli.com/public/uploads/'+item.image,
              medium:'https://casamexcalli.com/public/uploads/'+item.image,
              big:'https://casamexcalli.com/public/uploads/'+item.image,
            });
          }
          this.galleryImages = imgAux;
          for(let i=1; i <= this.object.availability; i++){
            this.fakeQuantity.push(i);
          }
          if(this.object.attributesList.length > 0){
            this.metadata.capacity = this.object.attributesList[0].capacity;
            this.showProductPriceList(this.object.attributesList[0]);
          }
          //Calculamos descuento para mostrar en html
          if(this.object.attributesList.length == 0){
            if(this.object.discount){
              this.calculateDiscount(this.object.discountValue,this.metadata.unitPrice);
            }
          }
          this.getProducts();
          this.updateLastSee(this.object);
          this.loading.hide();
        },error=>{
          console.log('product:get',error);
          this.loading.hide();
        })
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /*funcionalidad para agregar un producto al carrito de compras
  */
  addProductToShoopingCart(){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      this.object.price = this.metadata.unitPrice;
      for(let item of this.metadata.productPriceList){
        if(item.selected){
          this.metadata.productPriceSelected = JSON.parse(JSON.stringify(item));
        }
      }
      // this.object.attributesList = [];
      shoppingCart.push({
        quantity:this.metadata.quantity,
        product:this.object,
        productPrice:this.metadata.productPriceSelected
      });
      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      // this.object.image = 'https://casamexcalli.com/public/uploads/'+this.object.image;
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        this.snackBar.open('Listo, este producto se agrego a tu carrito.', 'Aceptar', {duration: 5000});
        const dialogRef = this.dialog.open(ClientProductShoopingComponent , {
          width: '50%',
          data: this.object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
            }else{
              // El modal se cerro sin objeto
              // this.snackBar.open('No se guardo ningun registro', '', {
              //   duration: 1000
              // });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            // this.snackBar.open('No se guardo ningun registro', '', {
            //   duration: 1000
            // });
          }
        });
      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad para obtener el lsuatdo de productos
  */
  getProducts(){
    this.loading.show(true,"Espere un momento...")
    this.session.postRequestAnonimus("product:findAllByMaker",this.productMin).subscribe((data:any)=>{
      // console.log(data);
      for(let i = 0; i < data.object.instanceList.length; i++){
        data.object.instanceList[i].image = 'https://casamexcalli.com/public/uploads/'+data.object.instanceList[i].image;
      }
      this.products = data.object.instanceList;
      this.loading.hide();
    },error=>{
      console.log("product:findAllByStatusAndOutstanding:error",error);
      this.loading.hide();
    })
  }
  /*Funcion para regresar*/
  backClicked() {
  this._location.back();
}

/**función para obtener los atributos de un producto
*/
  getAttributes(){
    this.session.postRequest("productAttribute:findAllByProduct",{product:{id:this.object.id}}).subscribe((data:any)=>{
      this.productAttributeList = data.object.instanceList;
      console.log(this.productAttributeList);
    },error=>{
      console.log(error);
    });
  }

  showProductPriceList(object){
    this.metadata.productPriceList = JSON.parse(JSON.stringify(object.productPrices));
    this.selectedProductPrice(this.metadata.productPriceList[0]);
    // console.log(":::",this.metadata.productPriceList);
  }

  capacitySelected(){
    for(let item of this.object.attributesList){
      if(item.capacity == this.metadata.capacity){
        this.showProductPriceList(item);
        break;
      }
    }
  }

  selectedProductPrice(object){
    for(let i = 0; i < this.metadata.productPriceList.length; i++){
      this.metadata.productPriceList[i].selected = false;
    }
    object.selected = true;
    this.metadata.unitPrice = object.price;
    //Calculamos descuento para mostrar en html
    if(this.object.discount){
      this.calculateDiscount(this.object.discountValue,this.metadata.unitPrice);
    }
  }

  /**funcionalidad para calcular el descuento de un producto
  */
  calculateDiscount(discountValue,price){
    this.metadata.unitPriceDiscount = price;
    this.metadata.unitPrice = (this.metadata.unitPriceDiscount * (100-discountValue))/100;
  }
  /**funcionalida para agregar un producto a lo ultimo visto
  */
  updateLastSee(object){
    this.storage.getAkeyFromStorage('lastSeeMexcalli').then((data:any)=>{
      let lastSee = [];
      if(data){
        lastSee = data;
      }
      // this.object.attributesList = [];
      let found = false;
      for(let  i = 0; i< lastSee.length; i++){
        if(lastSee[i].id == object.id){
          found = true;
        }
      }
      if(!found){
        // console.log(object);
        // object.image =  this.session.urlIMG+'uploads/'+object.image;
        object.showImage = object.image;
        object.images = [];
        for(let item of this.galleryImages){
          object.images.push({image:item.big});
        }
        lastSee.unshift(object);

        if(lastSee.length >= 9){
          lastSee.pop();
        }
        this.storage.saveAKeyOnTheStorage('lastSeeMexcalli',lastSee).then(()=>{
          // console.log("Agregado a lo ultimo visto")
        }).catch(e=>{
          console.log(e);
        })
      }
    }).catch(e=>{
      console.log(e);
    })
  }

  /**funcionalidad para ver lo ultimo vistado
  */
  getLastSeeProducts(){
    this.storage.getAkeyFromStorage('lastSeeMexcalli').then((data:any)=>{
      if(data){
        this.productLastSee = data;
      }
    }).catch(e=>{
      console.log(e);
    })
  }

  // funcion para obtener los datos del paginado.
  scrollTo(element:HTMLElement){
    setTimeout(function(){
      element.scrollIntoView({behavior: 'smooth'});
    }, 100);
  }

}
