import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { ClientBookingComponent } from '../client-booking/client-booking.component';
import { SessionService } from '../../service/session/session.service';
import { ClientMenuService } from './client-menu.service';

@Component({
  selector: 'app-client-menu',
  templateUrl: './client-menu.component.html',
  styleUrls: ['./client-menu.component.css']
})
export class ClientMenuComponent implements OnInit {
  bannerMin = {
    max: 100,
    page: 1,
  }
  banners = [];
  metadata = {
    selected:'home'
  }
  public isMenuCollapsed = true;

  constructor(public dialog: MatDialog, public session:SessionService, public menuService:ClientMenuService) { }

  ngOnInit(): void {
    this.getBanners();
  }
  select(element:any){
    this.metadata.selected=element;
    this.menuService.setMenuItem(this.metadata.selected);
  }
  /**Modal para reservar
  */
  bookingModal(){
    this.dialog.open(ClientBookingComponent, {
      width: '60rem',
      // data: uuid
    }).afterClosed().subscribe(result => {
    });
  }

  /**función para obtener el listado de banners
  */
  getBanners(){
    this.session.postRequestAnonimus("banner:list",this.bannerMin).subscribe((data:any)=>{
      for(let i = 0 ; i < data.object.instanceList.length; i++ ){
        data.object.instanceList[i].image = this.session.urlIMG + 'banners/'+data.object.instanceList[i].image;
      }
      this.banners = data.object.instanceList;
    },error=>{
      console.log("error:banner:list",error);
    });
  }

}
