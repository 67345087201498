import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-guides-update',
  templateUrl: './admin-guides-update.component.html',
  styleUrls: ['./admin-guides-update.component.css']
})
export class AdminGuidesUpdateComponent implements OnInit {
  complexForm: FormGroup;
  deliveryMin = {
    max: 100,
    page: 1,
  }
  listDelivery = [];

  guideModel = {
    id:'',
    guide:'',
    delivery:{
      id:''
    },
    used:false,
  }

  object = JSON.parse(JSON.stringify(this.guideModel));
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminGuidesUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'guide': [null, Validators.required],
      'delivery': [null, Validators.required],
    })
  }

  ngOnInit() {
    this.getDeliveries();
    if(this.data != 'new') {
      this.loadingService.wait();
       // cargamos los datos de la BD
       this.session.postRequest('guide:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.loadingService.hide();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: guide:get',error)
       })
    }
  }

  /**
   * funcion para guardar una registro
   */
  send(object){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("guide:update",object).subscribe(
      (data:any) => {
        this.snackBar.open('El guide se guardo correctamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
        this.close(true);
      },
      error => {
        console.log("Error: guide:update",error);
        this.loadingService.hide();
      }
    );
  }

  /**función para obtener el listado de categorias
  */
  getDeliveries(){
    this.session.postRequest("delivery:list",this.deliveryMin).subscribe((data:any)=>{
      this.listDelivery = data.object.instanceList;
    },error=>{
      console.log("error:delivery:list",error);
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'guide:001';
    }
    this.dialogRef.close(object);
  }

}
