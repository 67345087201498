<app-client-menu></app-client-menu>
<div class="row bg-prod justify-content-center" style="margin-top: 70px !important">
  <div class="col-md-10 col-12">
    <div class="row mt-2">
      <div class="col-md-6 col-11">
        <p class="text-uppercase display-4 pt-4 tittle" >{{object.name}}</p>
        <p class="mt-5 text-us">{{object.description}}<br/><br/>
La primera tienda de Casa Mexcalli se localiza en Calle Hidalgo #111 Barrio de Jalatlaco,
Oaxaca, Oax.</p>
      </div>
      <div class="col-md-6 col-11 p-5">
        <div class="card-1">
          <img src="{{object.img}}" class="img-us">
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 mt-5">
        <p class="tittle text-center h2">Mezcales de nuestra selección</p>
      </div>
      <div class="col-lg-3 col-md-4 col-6 p-2" *ngFor="let object of list" >
        <div class="col-12 m-0 p-0" style="overflow: hidden; height: 20rem; cursor: pointer; border: 1px solid #e0e0e0; background:white;">
          <div class="img-card col-12 p-0">
            <div class="bandera" *ngIf="object.discount">
              <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
            </div>
            <img src="{{object.image}}" alt="{{object.name}}" class="image" routerLink="/client/product/detail/{{object.id}}">
            <button type="button"  [ngClass]="object.like ? 'boton-like':'boton-dislike'" (click)="object.like=!object.like"  [matTooltip]="object.like ? 'Quitar de favoritos':'Agregar a favoritos'"></button>
          </div>
          <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
            <ul class="color">
              <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
                <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
              </li>
            </ul>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/detail/{{object.id}}">
            <p class="producto">{{object.name}}</p>
            <p class="marca">{{object.maker.name}}</p>
          </div>
          <div class="row justify-content-center" style="width: 100%; margin: 0px;">
            <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center m-0">
      <button  mat-button color="primary" class="col-md-4 col-12" *ngIf="metadata.getMoreProducts" (click)="showMoreProducts();">VER MÁS PRODUCTOS</button>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
