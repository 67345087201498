import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { Subject } from "rxjs";
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-admin-product',
  templateUrl: './admin-product.component.html',
  styleUrls: ['./admin-product.component.css']
})
export class AdminProductComponent implements OnInit {

  productMin = {
    filter:'',
    max: 10,
    page: 1,
  }

  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;

  metadata = {
    searchBoxInput: new Subject<string>(),
  }

  constructor(private session: SessionService,
    public loadding:LoadingService) {
      this.metadata.searchBoxInput.pipe(debounceTime(700),
        switchMap(val => {
          console.log(val);
          if (val != '') {
            this.list = [];
            // this.productMin.filter = '';
          } else {
            this.list = [];
          }
          this.getProducts();
          return val;
        })
      ).subscribe(()=>{});
    }

    ngOnInit(): void {
      this.getProducts();
    }

      /**función para obtener el listado de categorias
      */
      getProducts(){
        if(this.productMin.filter == ''){
          this.session.postRequest("product:list",this.productMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
          },error=>{
            console.log("error:product:list",error);
          })
        }else{
          this.session.postRequest("product:searching",this.productMin).subscribe((data:any)=>{
            this.list = data.object.instanceList;
            this.paginator.total = data.object.total;
          },error=>{
            console.log("error:product:list",error);
          })
        }

      }

      delete(object){
        this.loadding.show(true,"Espero un momento..");
        this.session.postRequest("product:delete",object).subscribe((data:any)=>{
          for(let  i = 0; i < this.list.length; i++){
            if(this.list[i].id == object.id){
              this.list.splice(i,1);
            }
          }
          this.loadding.hide();
        },error=>{
          this.loadding.hide();
          console.log("error:product:list",error);
        })
      }

      search(){
        this.productMin.page = 1;
        this.metadata.searchBoxInput.next(this.productMin.filter);
      }

      // funcion para obtener los datos del paginado.
      onPaginateChange(event){
        this.productMin.max = event.pageSize;
        this.productMin.page = event.pageIndex + 1;
        this.getProducts();
      }


}
