<div class="row justify-content-center">
  <div class="col-12">
    <h2 class="mb-4 tittle">Solicitud de reservación para una degustación</h2>
    <p> <strong>Casa Mexcalli</strong>  esta hecha para que pases momentos especiales acompañado de tus personas favoritas, y que mejor, acompañados de un excelente Mezcal.</p>
    <mat-form-field style="width:100%;" appearance="outline">
      <mat-label>Nombre completo</mat-label>
      <input matInput placeholder="Nombre" name="name" [formControl]="controlForm.controls['name']" [(ngModel)]="object.name">
      <mat-error *ngIf="controlForm.controls['name'].hasError('required')">
        Campo <strong>requerido</strong>.
      </mat-error>
    </mat-form-field>
  </div>

  <div class="col-12 mt-2">
    <mat-form-field style="width:100%;" appearance="outline">
      <mat-label>Número de personas</mat-label>
      <input matInput placeholder="Número personas" name="person" [formControl]="controlForm.controls['person']" [(ngModel)]="object.email">
      <mat-error *ngIf="controlForm.controls['person'].hasError('required')">
        Campo <strong>requerido</strong>.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12 mt-2">
    <mat-form-field style="width:100%;" appearance="outline">
      <mat-label>Teléfono</mat-label>
      <input matInput placeholder="Teléfono" name="phone" [formControl]="controlForm.controls['phone']" [(ngModel)]="object.phone">
      <mat-error *ngIf="controlForm.controls['phone'].hasError('required')">
        Campo <strong>requerido</strong>.
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-md-6 col-12">
        <mat-label>¿Que fecha te gustaría reservar?</mat-label>
      </div>
      <mat-form-field color="accent" class="col-md-6 col-12">
        <mat-label>DD/MM/YYYY</mat-label>
        <input matInput [matDatepicker]="picker1" (click)="picker1.open()" name="meetdate"  [formControl]="controlForm.controls['meetdate']" [(ngModel)]="metadata.date">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <mat-error *ngIf="controlForm.controls['meetdate'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="row justify-content-end">
  <div class="col-6 text-end">
    <button type="button" class="btn btn-outline-secondary mt-3 mb-3" [disabled]="controlForm.invalid" (click)="send(object)">ENVIAR RESERVACIÓN</button>
  </div>
</div>
