import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SessionService } from './session.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private session: SessionService, private router: Router) {
    //verificamos si existen variables de session para permitir el reload
    this.session.realodSession().then(data =>{
      if(data){
        // no hacemos nada y permitimos el reload
      }else{
        sessionStorage.clear();
        let srt = this.router.url;
        if(srt.search(/payment/i) >= 0){
        }else{
          this.router.navigate(['/']);
        }
      }
    })
   }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log(next.data.access)
    let access = false;
    let roles = next.data.access;
    let userRoles = this.session.getRoles();
    // console.log(userRoles);
    // recorremos los roles para saber si hay acceso
    for(let item of  roles){
      switch(item){
        case 'ANONYMOUS':{
          access = true;
          break;
        }
        default:{
          // verificamos si el usuario tiene acceso con alguno de sus roles
          if(userRoles.length > 0){
            for(let userRol of userRoles){
              if(userRol == item){
                access = true;
                // console.log("Tiene acceso");
              }
            }
          }
          break;
        }
      }
    }
    // si el usuario no obtiene acceso lo enviamos al login
    if(!access)
      this.router.navigate(['/']);
    return access;
  }
}
