<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nueva Guia</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-5">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Guia</mat-label>
        <input matInput placeholder="Guia" name="mguide" [(ngModel)]="object.guide" [formControl]="complexForm.controls['guide']">
        <mat-error *ngIf="complexForm.controls['guide'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Mensajeria</mat-label>
        <mat-select name="delivery" [(ngModel)]="object.delivery.id" [formControl]="complexForm.controls['delivery']" required>
          <mat-option *ngFor="let object of listDelivery" [value]="object.id">
            {{object.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="complexForm.controls['delivery'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]=" complexForm.invalid "  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
