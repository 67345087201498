<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nueva categoria</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-5">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Nombre</mat-label>
        <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.name" [formControl]="complexForm.controls['name']">
        <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Descripción</mat-label>
        <textarea matInput placeholder="Descripción" name="mdescription" [(ngModel)]="object.description"></textarea>
      </mat-form-field>
      <!-- <hr> -->
      <!-- <div class="col-12">
        <p>Ingles</p>
      </div>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Name</mat-label>
        <input matInput placeholder="Nombre" name="enName" [(ngModel)]="object.en_name">
      </mat-form-field>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Description</mat-label>
        <textarea matInput placeholder="Descripción" name="en_description" [(ngModel)]="object.en_description"></textarea>
      </mat-form-field> -->

    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]=" complexForm.invalid "  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
