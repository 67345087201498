import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { CommonModule } from '@angular/common';
import { AuthenticationGuard } from './authentication.guard';
import { SessionService } from './session.service';
import { StorageTool } from './storage.tool';
export * from './session.service';
export * from './authentication.guard';
export * from './storage.tool';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
  ],
  providers: [
    SessionService,
    AuthenticationGuard,
    StorageTool
  ],
  exports: [
  ]
})
export class SessionModule {
  public static forRoot(): ModuleWithProviders< SessionModule > {
    return {
      ngModule: SessionModule,
    };
  }
 }
