import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { ClientMenuService } from '../client-menu/client-menu.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject } from "rxjs";
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-client-home',
  templateUrl: './client-home.component.html',
  styleUrls: ['./client-home.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ClientHomeComponent implements OnInit {
  @ViewChild('us') us: ElementRef;
  @ViewChild('brand') brand: ElementRef;
  bannerMin = {
    max: 100,
    page: 1,
  }
  banners = [];
  makerMin = {
    max: 200,
    page: 1,
  }
  makers = [];
  productMin = {
    status: true,
    order:'',
    max:100,
    page:1,
    category:{
      id:''
    },
    maker:{
      id:''
    },
    filter:''
  }
  products = [];
  metadata = {
    secction:'',
    ourIMG:'',
    searchBoxInput: new Subject<string>(),
  }
  ourContent:any = '';
  home = {
    id:'',
    section_our_img:'',
    section_our_title:'',
    section_our_content:''
  }
  constructor(public session:SessionService,
    public loading:LoadingService,
    public menuService:ClientMenuService,
    public router:Router,
    public location:Location,
    private sanitizer: DomSanitizer
  ) {
    this.metadata.secction = this.menuService.getMenuItem().enabled;
    this.scrollMenuInit(this.metadata.secction);
    this.metadata.searchBoxInput.pipe(debounceTime(700),
      switchMap(val => {
        console.log(val);
        if (val != '') {
          this.products = [];
          // this.productMin.filter = '';
        } else {
          this.products = [];
        }
        this.findProductByMaker();
        return val;
      })
    ).subscribe(()=>{});
   }

  ngOnInit(): void {
    this.getBanners();
    this.getmaker();
    this.getHomeData();
    this.metadata.secction = this.menuService.getMenuItem().enabled;
    this.menuService.menuItemEnabled.subscribe((value)=>{
      this.scrollMenu(value.enabled);
    });
  }

  /**funcionalidad para obtener los datos de nosotros
  */
  getHomeData(){
    this.session.postRequest('home:get',{id:1}).subscribe((data:any)=>{
      if(data.object){
        this.home = JSON.parse(JSON.stringify(data.object));
        this.ourContent = this.sanitizer.bypassSecurityTrustHtml(this.home.section_our_content);
        this.metadata.ourIMG = 'https://casamexcalli.com/public/homeMexcalli/'+this.home.section_our_img;
        console.log(this.metadata.ourIMG);
      }
    },
    (error)=>{
      console.log('Error: home:get',error)
    })
  }
/**funcionalidad para mover el menu a la seccion solicitada desde  otra pagina
*/
scrollMenuInit(value){
  switch(value){
    case 'us':
      setTimeout(() =>
      {
        this.scrollTo(this.us.nativeElement);
      },2000);
    break;
    case 'brand':
    setTimeout(() =>
    {
      this.scrollTo(this.brand.nativeElement);
    },2000);
    break;
    default:
    break;
  }
}

/**Realizar scroll para la seccion seleccionada desde el home principal
*/
  scrollMenu(value){
    let location = this.location.path();
    switch(value){
      case 'us':
        if(location == '/client/home'){
          setTimeout(() =>
          {
            this.scrollTo(this.us.nativeElement);
          },500);
        }else{
          this.router.navigate(['/']);
        }
      break;
      case 'brand':
        if(location == '/client/home'){
          setTimeout(() =>
          {
            this.scrollTo(this.brand.nativeElement);
          },500);
        }else{
          this.router.navigate(['/']);
        }
      break;
      default:
      break;
    }
  }

  /**función para obtener el listado de categorias
  */
  getmaker(){
    this.session.postRequest("maker:list",this.makerMin).subscribe((data:any)=>{
      this.makers = data.object.instanceList.sort(function(a, b) {
        return a.id - b.id;
      });
      if(this.makers.length > 0){
        for(let i = 0; i < this.makers.length; i++){
          this.makers[i].select = false
        }
        this.makers[0].select = true;
        this.productMin.maker.id = this.makers[0].id;
        this.findProductByMaker();
      }
    },error=>{
      console.log("error:maker:list",error);
    })
  }

  /**función para obtener el listado de banners
  */
  getBanners(){
    this.session.postRequestAnonimus("banner:list",this.bannerMin).subscribe((data:any)=>{
      for(let i = 0 ; i < data.object.instanceList.length; i++ ){
        data.object.instanceList[i].image = this.session.urlIMG + 'banners/'+data.object.instanceList[i].image;
      }
      //construimos la descripción del texto de banner
      for(let  j = 0; j < data.object.instanceList.length; j++){
        if(data.object.instanceList[j].description){
          let descriptionHTML =  '';
          descriptionHTML = data.object.instanceList[j].description.replace(/,/g, ",<br><br>");
          descriptionHTML = descriptionHTML.replace(/Casa Mexcalli/g, "<span class=\"text-Mexcalli\">Casa Mexcalli</span>");
          data.object.instanceList[j].description = descriptionHTML;
        }
      }
      this.banners = data.object.instanceList;
    },error=>{
      console.log("error:banner:list",error);
    });
  }

  /**funcionalidad para obtener productos por marca
  */
  findProductByMaker(){
    this.products = [];
    if(this.productMin.filter == ''){
      this.loading.show(true,"Espere un momento");
      this.session.postRequest("product:findAllByMaker",this.productMin).subscribe((data:any)=>{
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
        }
        this.products = data.object.instanceList;
        this.loading.hide();
      },error=>{
        console.log("error:product:findAllByMaker",error);
        this.loading.hide();
      })
    }else{
      this.loading.show(true,"Buscando...");
      this.session.postRequest("product:searching",this.productMin).subscribe((data:any)=>{
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
        }
        this.products = data.object.instanceList;
        this.loading.hide();
      },error=>{
        console.log("error:product:list",error);
        this.loading.hide();
      })
    }

  }

  findProductByMakerAux(object){
    for(let i = 0; i < this.makers.length; i++){
      this.makers[i].select = false
    }
    object.select = true;
    this.productMin.maker.id = object.id;
    this.findProductByMaker();
  }

  search(){
    this.productMin.page = 1;
    if(this.productMin.filter!=''){
      this.metadata.searchBoxInput.next(this.productMin.filter);
    }else{
      this.findProductByMaker();
    }
  }

  openBuy(){
    let makerId = '';
    for(let item of this.makers){
      if(item.select)
        makerId = item.id;
    }
    this.router.navigate(['client/product/'+makerId]);
  }

  // funcion para obtener los datos del paginado.
  scrollTo(element:HTMLElement){
    setTimeout(function(){
      element.scrollIntoView({behavior: 'smooth'});
    }, 100);
  }

}
