<div class="row justify-content-center m-0">
  <div class="col-12">
    <div class="row">
      <div class="col-12" style="padding-left: 10px; text-align: start">
        <div class="row mt-3">
          <h3 style="color: #666666;" class="col-12 text-center"><span class="material-icons mr-2" style="color:#666666;">shopping_cart</span> El producto <strong style="color: #c6945d;">{{object.name}} </strong>ha sido agregado correctamente a tu carrito de compras</h3>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3 mb-2" style="width: 100%;">
      <button type="button" class="btn-verde2 col-md-4 col-12" (click)="close(false);" >CONTINUAR COMPRANDO</button>
      <div class="col-1"></div>
      <button type="button" class="btn-naranja btn-shopping col-md-4 col-12" (click)="close(false);"  routerLink="/client/cart">PAGAR</button>
    </div>
  </div>
</div>
