import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-guides-uploadexcel',
  templateUrl: './admin-guides-uploadexcel.component.html',
  styleUrls: ['./admin-guides-uploadexcel.component.css']
})
export class AdminGuidesUploadexcelComponent implements OnInit {
  @ViewChild("excelGet", {static: false}) fileGet: ElementRef;
  complexForm: FormGroup;
  deliveryMin = {
    max: 100,
    page: 1,
  }
  listDelivery = [];
  guideModel = {
    id:'',
    guide:'',
    delivery:{
      id:''
    },
    used:false,
  }
  metadata = {
    img:{
      file: '',
      type: '',
      name: '',
    },
    uploadted:false
  }
  object = JSON.parse(JSON.stringify(this.guideModel));

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminGuidesUploadexcelComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'delivery': [null, Validators.required],
    })
  }

  ngOnInit(): void {
    this.getDeliveries();

  }

  /**función para obtener el listado de categorias
  */
  getDeliveries(){
    this.session.postRequest("delivery:list",this.deliveryMin).subscribe((data:any)=>{
      this.listDelivery = data.object.instanceList;
    },error=>{
      console.log("error:delivery:list",error);
    })
  }

  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     console.log("FILE",fileUpload.files[0]);
     this.metadata.img.file = file;
     console.log("FILE",this.metadata.img);
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loadingService.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      formData.append('delivery', this.object.delivery.id);
      this.session.uploadFile("guide:uploadExcel",formData).subscribe(async(data:any)=>{
        console.log(data);
        if(data){
          this.close(true);
        }
        this.loadingService.hide();
        this.snackBar.open('La excel importado con subio con exito', 'Aceptar', {duration: 5000});
        // this.loadingService.hide();
      },error=>{
        console.log("banner:upload",error);
        this.loadingService.hide();
      })
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'guide:001';
    }
    this.dialogRef.close(object);
  }

}
