<app-client-menu></app-client-menu>
<div class="row mb-5">
  <div class="col-12">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let object of banners">
        <div class="picsum-img-wrapper" *ngIf="!object.url">
          <img [src]="object.image" alt="{{object.name}}" width="100%" class="imgBanner">
        </div>
        <div class="picsum-img-wrapper" *ngIf="object.url">
          <a href="{{object.url}}">
            <img [src]="object.image" alt="{{object.name}}" width="100%" class="imgBanner">
          </a>
        </div>
        <div class="text-banner" *ngIf="object.description">
          <p [innerHtml]="object.description"></p>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row justify-content-end nosotros" [ngStyle]="{'background-image': 'url(' + metadata.ourIMG + ')'}">
  <div class="col-md-6 col-11">
    <p class="text-uppercase display-3 mt-5 pt-2 tittle text-white" #us>{{home.section_our_title}}</p>
    <div [innerHtml]="ourContent"></div>
    <!-- <h4 class="text-warning">PROMOVEMOS LA CULTURA DEL MEZCAL</h4>
    <h4 class="text-warning">La primera tienda de casa mexcalli se úbica en el Barrio de Jalatlaco, Oaxaca</h4>
    <p class="mt-2 text-us pr-3">Estamos comprometidos con la industria del mezcal, por ello garantizamos nuestros consumidores una experiencia única a través de nuestra tienda donde encontrarán productos de las mas alta calidad y marcas premium certificadas. <br><br>
      Buscamos incluir a productores en crecimiento, impulsando sus marcas a través de estrategias tecnológicas que los posicionen con el consumidor. <br><br>
      No es un secreto que el estado de Oaxaca posee una gran riqueza cultural, por ello además del mezcal encontrarás productos deliciosos de tradición Oaxaqueña. <br><br>
    </p> -->
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-10 col-12 text-center">
    <p class="text-uppercase display-3 mt-5 pt-2 tittle" #brand>Mezcales</p>
    <mat-form-field class="col-12 col-md-10">
      <input matInput placeholder="Buscar" [(ngModel)]="productMin.filter" (keyup.enter)="search()" (ngModelChange)="search()" >
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
    <div class="h5">
      <div class="btn_maker" *ngFor="let object of makers" (click)="findProductByMakerAux(object)">
        <span class="badge" [ngClass]="{'bg-dark':object.select , 'badge-light':!object.select}">{{object.name}}</span>&nbsp;
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-4 col-6 mt-2" *ngFor="let product of products">
        <mat-card style="background-color:#37383b; height: 35rem; padding: 0px; cursor: pointer;" routerLink="/client/product/detail/{{product.id}}">
          <div class="boxImgHome">
            <img mat-card-image src="{{product.image}}">
          </div>
          <mat-card-content>
            <h4 class="text-white mb-0 mt-2">{{product.name}}</h4>
            <h4 class="text-white" *ngIf="product.category">{{product.category.name}}</h4>
            <p class="text-white text-justify pl-2 pr-2 text-prod-home">
              {{product.short_description}}
            </p>
          </mat-card-content>
        </mat-card>
        <h1 class="mt-1 mb-1">{{product.price | currency:'MXN':'symbol-narrow'}}</h1>
      </div>
    </div>
  </div>
</div>
<div class="row justify-content-center mt-4 mb-4">
  <div class="col-md-4 col-10 d-grid">
    <button type="button" class="btn btn-dark text-warning btn-lg rounded-pill" (click)="openBuy();">COMPRAR AHORA <span class="material-icons">shopping_cart</span></button>
  </div>
</div>
<app-client-footer></app-client-footer>
