<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Importar guia de excel</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-5">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>Mensajeria</mat-label>
        <mat-select name="delivery" [(ngModel)]="object.delivery.id" [formControl]="complexForm.controls['delivery']" required>
          <mat-option *ngFor="let object of listDelivery" [value]="object.id">
            {{object.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="complexForm.controls['delivery'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <div class="col-12">
        <p>Agregar tu documento excel</p>
        <input type="file" #excelGet id="imgGet" name="imgGet" style="display:none;" accept=".xlsx, .xls, .csv"/>
        <div class="cardImg">
          <span *ngIf="metadata.img.name == ''">Agregar imagen</span>
          <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
          <!-- <button mat-raised-button color="primary" class="cardImgButtom">Subir</button> -->
          <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
          <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-6">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]=" complexForm.invalid "  (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted" >Guardar</button>
      </div>
    </div>
  </div>
</div>
