import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from './../../directive/loading/loading.module';
import { SessionService } from './../../service/session/session.module';
import * as moment from 'moment';
moment.locale('es-us');

@Component({
  selector: 'app-client-search',
  templateUrl: './client-search.component.html',
  styleUrls: ['./client-search.component.css']
})
export class ClientSearchComponent implements OnInit {

  shippingModel = {
    id:'',
    cp:'',
    street:'',
    colony:'',
    city:'',
    state:'',
    observations:'',
    delivery:'',
    sale:{
      id:''
    }
  }
  saleModel = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'',
    clientEmail:'',
    platform:'web',
    typePayment:'',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: false,
    privacy:false,
    user:{
      id:0
    },
    shipping:this.shippingModel,
    saleProducts:[]
  };
  saleList = [];
  object = JSON.parse(JSON.stringify(this.saleModel));
  mexpago = {
    folioMexPago:'',
    monto:'',
    numeroTransaccion:'',
    pago:''
  }
  metadata = {
    status:'',
    phone:'',
    folio:''
  }
  saleProductsList = [];
  guidesList = [];

  constructor(protected session: SessionService, public loading: LoadingService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['phone'] != 'mexpago') {
        // console.log(params);
        this.metadata.phone = params['phone'];
        this.activatedRoute.queryParams.subscribe(async(params) => {
          // console.log(params);
          this.metadata.folio = params.folio;
          this.getSale(this.metadata.phone,this.metadata.folio);
        });
      }else{
        console.log(params['phone']);
        this.activatedRoute.queryParams.subscribe(async(params) => {
          console.log(params);
          this.mexpago.folioMexPago = params.folioMexPago;
          this.mexpago.monto = params.monto;
          this.mexpago.numeroTransaccion = params.numeroTransaccion;
          this.mexpago.pago = params.pago;
          this.metadata.status = params.pago;
          this.getSaleByMexpago();
        });
      }
    });
    console.log(this.object);
  }

  /**funcionalidad para obtener las guias asignadas
  */
  getGuides(){
    this.session.postRequestAnonimus("guide:findAllBySale",{sale:{id:this.object.id}}).subscribe((data:any)=>{
      console.log("Guidas:::::::",data.object.instanceList);
      this.guidesList = data.object.instanceList;
    },error=>{
      console.log(error);
    });
  }

  /**funcion para obtener las refervaciones por numero telefonico
  */
  getSale(phone,folio){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("sale:findByPhoneAndFolio",{phone:phone,folio:folio}).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      this.getGuides();
      this.getProductlist();
      this.loading.hide();
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }

  /**función para seleccionar una reservación
  */
  selectedsale(object){
    this.object = JSON.parse(JSON.stringify(object));
    this.getProductlist();
  }

  /**función para obtener reservacion por mexpago
  */
  getSaleByMexpago(){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("sale:findByMexpago",this.mexpago).subscribe((data:any)=>{
      //actualizamos el pago
      this.object = data.object;
      this.getProductlist();
      let status = '';
      if(this.metadata.status == 'aprobado'){
        status = 'Pagado';
      }else{
        status = 'Cancelado';
      }
      this.session.postRequestAnonimus("sale:checkAndValidate",{
        id:this.object.id,
        paymentId:this.object.paymentId,
        folioMexPago:this.mexpago.folioMexPago,
        status:status
      }).subscribe((data:any)=>{
        //actualizamos el pago
        this.object.status = data.object.status;
        this.loading.hide();
      },error=>{
        console.log(error);
        this.loading.hide();
      })
    },error=>{
      console.log(error);
      this.loading.hide();
    })
  }
  /**funcionalidad para obetenr el producto
  */
  getProduct(object){
    this.session.postRequest("product:get",{id:object.product_id}).subscribe((data:any)=>{
      object.product = data.object;
      object.product.image = 'https://casamexcalli.com/public/uploads/'+object.product.image;
    },error=>{
      console.log(error);
    })
  }
  /**metodo para obetner los productos
  */
  getProductlist(){
    for(let i = 0; i < this.object.products.length; i++){
      this.getProduct(this.object.products[i]);
    }
    this.saleProductsList = this.object.products;
    console.log(this.saleProductsList);
  }

}
