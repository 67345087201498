<div class="row fondo-footer justify-content-center pt-5">
  <div class="col-md-3 text-center text-white mt-5 pt-5">
    <p class="h2 mt-2">Contacto</p>
    <dd style="font-size:1.1rem; font-weight: 600;">
      <span class="material-icons">room</span>&nbsp;Calle Hidalgo 111 Barrio de Jalatlaco, Oaxaca de Juárez, Oaxaca.
    </dd>
    <dd style="font-size:1.1rem; font-weight: 600;" class="mt-2">
      <span class="material-icons">whatsapp</span>&nbsp;+(52) 951 392 1236
    </dd>
    <dd style="font-size:1.1rem; font-weight: 600;" class="mt-2">
      <span class="material-icons">mail</span>&nbsp;contacto@casamexcalli.com
    </dd>
  </div>
  <div class="col-md-3 text-center text-white mt-5 pt-5">
    <p class="h2 mt-2">Siguenos en </p>
    <div class="btn-group" role="group" aria-label="Basic outlined example">
      <!-- <button type="button" class="btn btn-outline-dark" style="border-color:transparent;">
        <img src="assets/img/home-footer/facebook.png">
      </button>
      <button type="button" class="btn btn-outline-dark" style="border-color:transparent;">
        <img src="assets/img/home-footer/twitter.png">
      </button>
      <button type="button" class="btn btn-outline-dark" style="border-color:transparent;">
        <img src="assets/img/home-footer/tiktok.png">
      </button> -->
    </div>
    <dd style="font-size:1.2rem; font-weight: 600;" class="mt-2">
      @casamexcalli
    </dd>
  </div>
  <div class="col-md-3 text-center text-white mt-5 pt-5">
    <p class="h2 mt-2">Horario </p>
    <dd style="font-size:1.1rem; font-weight: 600;">
      Lunes a Domingo <br>
      11:00 AM - 10:00 PM
    </dd>
  </div>
  <div class="col-12 text-center text-white mt-4 mb-2">
    <p class="m-0">© Copyright 2022 - Casa Mexcalli</p>
  </div>
</div>
