import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';
import { KsfUploadFilesModule } from './directive/ksf-upload-files/ksf-upload-files.module';
import { UploadImgMinComponent } from './directive/upload-img-min/upload-img-min.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { ClientMenuService } from './client/client-menu/client-menu.service';
import { CKEditorModule } from 'ckeditor4-angular';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateAdapter as DateAdapterMaterial, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import { ClientHomeComponent } from './client/client-home/client-home.component';
import { ClientMenuComponent } from './client/client-menu/client-menu.component';
import { ClientProductComponent } from './client/client-product/client-product.component';
import { ClientWhyHouseMexalliComponent } from './client/client-why-house-mexalli/client-why-house-mexalli.component';
import { ClientGalleryComponent } from './client/client-gallery/client-gallery.component';
import { ClientBookingComponent } from './client/client-booking/client-booking.component';
import { ClientFooterComponent } from './client/client-footer/client-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DetailsProductComponent } from './client/client-product/details-product/details-product.component';
import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminToolbarComponent } from './admin/admin-toolbar/admin-toolbar.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminDeliveryComponent } from './admin/admin-delivery/admin-delivery.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminCategoryUpdateComponent } from './admin/admin-category/admin-category-update/admin-category-update.component';
import { AdminDeliveryUpdateComponent } from './admin/admin-delivery/admin-delivery-update/admin-delivery-update.component';
import { AdminBannerComponent } from './admin/admin-banner/admin-banner.component';
import { AdminBannerUpdateComponent } from './admin/admin-banner/admin-banner-update/admin-banner-update.component';
import { AdminMakerComponent } from './admin/admin-maker/admin-maker.component';
import { AdminMakerUpdateComponent } from './admin/admin-maker/admin-maker-update/admin-maker-update.component';
import { AdminProductComponent } from './admin/admin-product/admin-product.component';
import { AdminProductUpdateComponent } from './admin/admin-product/admin-product-update/admin-product-update.component';
import { ImageProductDirective } from './admin/admin-product/image-product-directive/image-product.directive';
import { ClientProductShoopingComponent } from './client/client-product/client-product-shooping/client-product-shooping.component';
import { ClientShoppingCartComponent } from './client/client-shopping-cart/client-shopping-cart.component';
import { ClientPaymentComponent } from './client/client-payment/client-payment.component';
import { ClientSearchComponent } from './client/client-search/client-search.component';
import { AdminSalesComponent } from './admin/admin-sales/admin-sales.component';
import { AdminSalesDetailsComponent } from './admin/admin-sales/admin-sales-details/admin-sales-details.component';
import { AdminBookingComponent } from './admin/admin-booking/admin-booking.component';
import { AdminBookingDetailsComponent } from './admin/admin-booking/admin-booking-details/admin-booking-details.component';
import { AdminMescaleroComponent } from './admin/admin-mescalero/admin-mescalero.component';
import { AdminGuidesComponent } from './admin/admin-guides/admin-guides.component';
import { AdminGuidesUpdateComponent } from './admin/admin-guides/admin-guides-update/admin-guides-update.component';
import { AdminGuidesUploadexcelComponent } from './admin/admin-guides/admin-guides-uploadexcel/admin-guides-uploadexcel.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    ClientHomeComponent,
    ClientMenuComponent,
    ClientProductComponent,
    ClientWhyHouseMexalliComponent,
    ClientGalleryComponent,
    ClientBookingComponent,
    ClientFooterComponent,
    DetailsProductComponent,
    UploadImgMinComponent,
    AdminLoginComponent,
    AdminToolbarComponent,
    AdminDashboardComponent,
    AdminDeliveryComponent,
    AdminCategoryComponent,
    AdminCategoryUpdateComponent,
    AdminDeliveryUpdateComponent,
    AdminBannerComponent,
    AdminBannerUpdateComponent,
    AdminMakerComponent,
    AdminMakerUpdateComponent,
    DetailsProductComponent,
    AdminProductComponent,
    AdminProductUpdateComponent,
    ImageProductDirective,
    ClientProductShoopingComponent,
    ClientShoppingCartComponent,
    ClientPaymentComponent,
    ClientSearchComponent,
    AdminSalesComponent,
    AdminSalesDetailsComponent,
    AdminBookingComponent,
    AdminBookingDetailsComponent,
    AdminMescaleroComponent,
    AdminGuidesComponent,
    AdminGuidesUpdateComponent,
    AdminGuidesUploadexcelComponent,
    AdminHomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatDatepickerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSelectModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatSidenavModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    NgxGalleryModule,
    CKEditorModule,
    SessionModule.forRoot(),
    LoadingModule.forRoot(),
    KsfUploadFilesModule.forRoot(),
  ],
  entryComponents:[
    ClientBookingComponent,
    UploadImgMinComponent,
    AdminDeliveryUpdateComponent,
    AdminCategoryUpdateComponent,
    AdminMakerUpdateComponent,
    ImageProductDirective,
    ClientProductShoopingComponent,
    AdminBookingDetailsComponent
  ],
  providers: [
    ClientMenuService,
    {provide: DateAdapterMaterial, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
