<div class="row p-2 m-0" [ngClass]="{'menuFixed' : metadata.menuFixed}" #menu style="height: 12vh; background: #243431; color:white">
  <div class="col-2 logo" style="height: 100%;" routerLink="/admin/dashboard">
    <img src="assets/img/home-menu/logo.png" style="height: 100%; padding: 5px;">
  </div>
  <div class="col-10 justify-content-end" style="padding: 0px; padding-top: 2vh;">
    <ul class="font-platino menu" data-animation="center">
      <!-- <li class="horizontal"><a routerLink="/admin/meeting" [ngClass]="metadata.currentUrl == '/admin/meeting' ? 'aActive':''">CITAS</a></li>
      <li class="horizontal"><a routerLink="/admin/contact" [ngClass]="metadata.currentUrl == '/admin/meeting' ? 'aActive':''">MENSAJES</a></li> -->
      <li class="horizontal"><a routerLink="/admin/set/home" [ngClass]="metadata.currentUrl == '/admin/set/home' ? 'aActive':''">HOME</a></li>
      <li class="horizontal"><a routerLink="/admin/sales" [ngClass]="metadata.currentUrl == '/admin/sales' ? 'aActive':''">PEDIDOS</a></li>
      <li class="horizontal"><a routerLink="/admin/product" [ngClass]="metadata.currentUrl == '/admin/product' ? 'aActive':''">PRODUCTO</a></li>
      <li class="horizontal"><a routerLink="/admin/banner" [ngClass]="metadata.currentUrl == '/admin/banner' ? 'aActive':''">BANER</a></li>
      <li class="horizontal"><a routerLink="/admin/booking" [ngClass]="metadata.currentUrl == '/admin/booking' ? 'aActive':''">RESERVACIONES</a></li>
      <li class="horizontal"><a routerLink="/admin/mescalero" [ngClass]="metadata.currentUrl == '/admin/mescalero' ? 'aActive':''">MESCALERO DEL MES</a></li>
      <!-- <li class="horizontal"><a routerLink="/admin/slide" [ngClass]="metadata.currentUrl == '/admin/slide' ? 'aActive':''">SECCIÓN</a></li> -->
      <!-- <li class="horizontal"><a routerLink="/admin/blog" [ngClass]="metadata.currentUrl == '/admin/blog' ? 'aActive':''">BLOG</a></li> -->
      <li class="horizontal"><a  [matMenuTriggerFor]="menuCatalog" style="cursor:pointer;">CATÁLOGOS</a></li>

      <li class="horizontal"><a (click)="logout();">SALIR</a></li>
    </ul>
  </div>
</div>

<mat-menu #menuCatalog="matMenu">
  <!-- <button mat-menu-item routerLink="/admin/place">Ubicaciones</button> -->
  <!-- <button mat-menu-item routerLink="/admin/buildingtype">Tipo de inmuebles</button> -->
  <button mat-menu-item routerLink="/admin/category">Categorías</button>
  <button mat-menu-item routerLink="/admin/delivery">Mensajería</button>
  <button mat-menu-item routerLink="/admin/maker">Marcas</button>
  <button mat-menu-item routerLink="/admin/guides">Guias</button>

</mat-menu>
