import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from './../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-mescalero',
  templateUrl: './admin-mescalero.component.html',
  styleUrls: ['./admin-mescalero.component.css']
})
export class AdminMescaleroComponent implements OnInit {

  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  complexForm: FormGroup;

  bannerModel = {
    id:'',
    name:'',
    description:'',
    status:true,
    img:'',
    maker:{
      id:''
    }
  }
  metadata = {
    ingles:false,
    img:{
      file: '',
      type: '',
      name: '',
    },
    uploadted:false
  }

  object = JSON.parse(JSON.stringify(this.bannerModel));
  makerMin = {
    max: 400,
    page: 1,
  }
  makerList = [];
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])],
      'maker': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    this.getmaker();
    this.loadingService.wait();
     // cargamos los datos de la BD
     this.session.postRequest('mothmezcalero:get',{id:1}).subscribe((data:any)=>{
       if(data.object){
         this.object = JSON.parse(JSON.stringify(data.object));
         this.metadata.img.name = this.object.img.substring(this.object.img.lastIndexOf("/")+1, this.object.img.length);
         this.metadata.uploadted = true;
       }
       this.loadingService.hide();
     },
     (error)=>{
       this.loadingService.hide();
       console.log('Error: mothmezcalero:get',error)
     })
  }

  /**
   * funcion para guardar una unidades de medida
   */
  send(object){
    this.loadingService.show(true,'Guardando registro...');
    this.session.postRequest("mothmezcalero:update",object).subscribe(
      (data:any) => {
        this.snackBar.open('Mezcalero se guardo exitosamente', '', {
          duration: 8000
        });
        this.loadingService.hide();
      },
      error => {
        console.log("Error: mothmezcalero:update",error);
        this.loadingService.hide();
      }
    );
  }


  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     console.log("FILE",fileUpload.files[0]);
     this.metadata.img.file = file;
     console.log("FILE",this.metadata.img);
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loadingService.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      this.session.uploadFile("mothmezcalero:upload",formData).subscribe(async(data:any)=>{
        this.object.img = data.object.name;
        this.metadata.uploadted = true;
        this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
        this.loadingService.hide();
      },error=>{
        console.log("mothmezcalero:upload",error);
        this.loadingService.hide();
      })
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

  /**función para obtener el listado de categorias
  */
  getmaker(){
    this.session.postRequest("maker:list",this.makerMin).subscribe((data:any)=>{
      this.makerList = data.object.instanceList;
    },error=>{
      console.log("error:maker:list",error);
    })
  }

}
