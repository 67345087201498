<app-client-menu></app-client-menu>
<div class="row justify-content-center background-why">
  <div class="col-md-5 col-12"></div>
  <div class="col-md-5 col-12 text-white">
    <p class="text-uppercase display-4 pt-4 tittle text-white">¿Por qué elegir Casa Mexcalli?</p>
    <p class="mt-3 text-us">
      Estamos comprometidos con el desarrollo de la industria del mezcal, por ello garantizamos
a nuestros consumidores una experiencia única a través de nuestra tienda donde encontrarán
productos de la más alta calidad y marcas premium certificadas.<br><br>
Actualmente, nuestros nichos exhiben las variedades de mezcal de Yuu Baal, El Silencio, Don
Sapo, La Venia, Mala Idea, Hacienda Oponguio, Doña Juana, El Discípulo, Divino Maguey,
Colores y Sabrá Dios.<br><br>
Buscamos incluir a más productores en crecimiento, impulsando sus marcas a través de
estrategias tecnológicas que los posicionen con el consumidor.<br><br>
No es un secreto que el estado de Oaxaca posee una gran riqueza cultural, por ello, además
del mezcal encontrarás productos deliciosos de tradición oaxaqueña.<br>
    </p>
    <div class="text-center">
      <button type="button" class="btn btn-outline-light mt-3 mb-3" (click)="bookingModal()">RESERVAR</button>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
