<app-client-menu></app-client-menu>
<div class="row justify-content-center" style="padding-bottom:90px;    margin-top: 120px !important;">
  <div class="col-11 col-md-10 col-lg-10 pt-3">
    <h4 style="display: inline; ">MI CARRITO</h4>
    <button  class="btn-verde" name="button" style="float: right;" (click)="backClicked();"> REGRESAR</button>

    <div class="row justify-content-start">

      <div class="col-12 col-lg-8" style="margin-top: 30px; padding: 0px 5px;">
        <div style="padding: 10px; padding-top: 0px;">
          <div class="table-prod">
                <table cellspacing="0">
                  <tr style="">
                      <th>Articulo</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                      <th></th>
                  </tr>
                  <tr style="" *ngFor="let object of shoopingCart">
                      <td>
                        <div class="row">
                          <div class="col-md-3 p-0" >
                            <img class="img-product" src="{{object.product.image}}" mat-list-icon  alt="" width="100%">
                          </div>
                          <div class="col-12 col-md-8">
                            <div class="" style="font-size: .8rem; color: #3c3c3b; text-align: left;">{{object.product.name}}</div>
                            <div class="" style="font-size: .8rem; color: #3c3c3b; text-align: left;" *ngIf="object.productPrice.id != ''"> {{object.productPrice.capacity}} | {{object.productPrice.wood}} | {{object.productPrice.varnish}} </div>

                          </div>
                        </div>
                      </td>
                      <td>
                        <p style="font-size: .75rem;" >{{object.product.price | currency:'MXN':'symbol-narrow'}}</p>
                      </td>
                      <td>
                        <div class="input-group" style="width:5.5rem;">
                          <div class="input-group-prepend" style="cursor:pointer;" (click)="changeQuantity('restar',object);">
                            <div class="input-group-text" id="btnGroupAddon">-</div>
                          </div>
                          <input name="quantity" class="form-control" style="text-align: center; font-size: .75rem;" [(ngModel)]="object.quantity" disabled>
                          <div class="input-group-append" style="cursor:pointer;" (click)="changeQuantity('sumar',object);">
                            <div class="input-group-text" id="btnGroupAddon">+</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <p style="font-size: .75rem;">{{(object.quantity*object.product.price)| currency:'USD':'symbol-narrow'}}</p>
                      </td>
                      <td>
                        <span class="material-icons" (click)="deleteAProduct(object);" style="cursor:pointer;">delete_outline</span>
                      </td>
                  </tr>
              </table>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4" style="margin-top: 10px; padding: 0px 5px;">
        <div class="row m-0" style="padding: 8px;">
          <img src="assets/img/formas-pago.png" alt="" style="width:15rem;">
        </div>
        <div class="row justify-content-center" style="background:#f5f5f5; PADDING-TOP: 10PX;">
          <div class="col-6" style="margin: 0px; padding: 0px 25px;">
            <ul style="list-style:none; padding: 0px; font-size:12pt; color:#6f6f6f;">
              <li style=" margin-bottom: 15px; color: #333;">TOTAL</li>
            </ul>
          </div>
          <div class="col-6" style="margin: 0px; padding: 0px 15px; text-align: center;">
            <ul  style="list-style:none; padding: 0px;">
              <li><h4 class="" style="color: #243431;">{{metadata.subtotal | currency:'MXN':'symbol-narrow'}}</h4></li>
            </ul>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="col-12">
            <button type="button" class="btn-dorado" style="width: 100%;" [disabled]="shoopingCart.length == 0" routerLink="/client/payment">REALIZAR PEDIDO</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
