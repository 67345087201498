<div class="login">
  <div class="row justify-content-center m-0">
    <div class="col-lg-4 col-md-4 col-10 mt-4">
      <mat-card style="margin-top: 5rem;">
        <mat-card-title>
          <img src="assets/img/home-menu/logo.png" alt="Mexcalli" style="width: 50%; margin-left: 25%; padding-bottom: 30px;">
        </mat-card-title>
        <mat-card-content>
          <form class="col login-form">
              <div class="col-12">
              </div>
              <mat-form-field class="col-12">
                <input matInput placeholder="Usuario" name="mName" [(ngModel)]="object.email" [formControl]="complexForm.controls['username']" >
                <mat-error *ngIf="complexForm.controls['username'].hasError('required')">
                  Campo <strong>requerido</strong>.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-12">
                <input matInput type="password" placeholder="Password" name="mPassword" [(ngModel)]="object.password" [formControl]="complexForm.controls['password']">
                <mat-error *ngIf="complexForm.controls['password'].hasError('required')">
                  Campo <strong>requerido</strong>.
                </mat-error>
              </mat-form-field>
              <br />
              <div class="button-row">
                <button class="col-12 login-button" mat-flat-button color="accent" (click)="send();" [disabled]="complexForm.invalid">Ingresar</button>
              </div>
          </form>
        </mat-card-content>
        <mat-card-footer class="text-center mb-1 mt-4">
          <small>
  				2022 Casa Mexcalli
  				</small><br/>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
