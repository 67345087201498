<nav class="nav navbar-expand-lg bg-dark fixed-top row pt-0 pb-0 justify-content-center">
  <div class="col-md-4 col-6">
    <img src="/assets/img/home-menu/logo.png" style="width: 11rem;" routerLink="/">
  </div>

  <button class="navbar-toggler col-6 text-center mt-1" type="button" (click)="isMenuCollapsed = !isMenuCollapsed" [attr.aria-expanded]="!isMenuCollapsed" aria-controls="collapseExample" aria-expanded="false" aria-label="Toggle navigation">
    <span class="material-icons text-white pl-5">dehaze</span>
  </button>
  <div class="col-md-8 col-12 collapse navbar-collapse justify-content-end" [ngbCollapse]="isMenuCollapsed" >
    <div class=" align-middle pl-5">
      <ul class="nav nav-pills justify-content-end text-center">
        <li class="nav-item" (click)="select('home')" >
          <a class="nav-link menu-client mt-2 text-uppercase text-white" routerLink="/client/home" [ngClass]="metadata.selected == 'home' ? 'menu-active':''">Inicio</a>
        </li>
        <li class="nav-item" (click)="select('us')" >
          <a class="nav-link menu-client mt-2 text-uppercase text-white" [ngClass]="metadata.selected == 'us' ? 'menu-active':''">Nosotros</a>
        </li>
        <li class="nav-item" (click)="select('brand')" >
          <a class="nav-link menu-client mt-2 text-uppercase text-white" [ngClass]="metadata.selected == 'brand' ? 'menu-active':''">Marcas</a>
        </li>
        <li class="nav-item" (click)="bookingModal()">
          <a class="nav-link menu-client mt-2 text-uppercase text-white">Reserva tu degustación</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/client/cart">
            <span class="material-icons text-white">shopping_cart</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>



<!-- <div class="row justify-content-center">
  <div class="col-md-10 col-12">
    <nav class="navbar navbar-expand-lg positonMenu">
      <div class="card">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="material-icons-outlined">menu</span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav" style="width:100%;">
            <li class="nav-item">
              <a class="nav-link" routerLink="/client/home">ACERCA DE NOSOTROS</a>
            </li>
            <!-- <hr width="3" size="500" class="separation-menu"> -->
            <!-- <li class="nav-item">
              <a class="nav-link" routerLink="/client/product">MEZCALES</a>
            </li> -->
            <!-- <hr width="3" size="500" class="separation-menu"> -->
            <!-- <li class="nav-item">
              <a class="nav-link" routerLink="/client/whyhousemexcalli">¿PORQUE CASA MEXCALLI?</a>
            </li> -->
            <!-- <hr width="3" size="500" class="separation-menu"> -->
            <!-- <li class="nav-item ">
              <a class="nav-link " routerLink="/client/galley">GALERIA</a>
            </li> -->
            <!-- <hr width="3" size="500" class="separation-menu"> -->
            <!-- <li class="nav-item ">
              <a class="nav-link " (click)="bookingModal()">RESERVAR</a>
            </li>
            <li class="nav-item ">
              <a class="nav-link " routerLink="/client/cart">CARRITO</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div> -->
