import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-sales-details',
  templateUrl: './admin-sales-details.component.html',
  styleUrls: ['./admin-sales-details.component.css']
})
export class AdminSalesDetailsComponent implements OnInit {

  shippingModel = {
    id:'',
    cp:'',
    street:'',
    colony:'',
    city:'',
    state:'',
    observations:'',
    delivery:'',
    sale:{
      id:''
    }
  }
  saleModel = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'',
    clientEmail:'',
    platform:'web',
    typePayment:'',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: false,
    privacy:false,
    user:{
      id:0
    },
    shipping:this.shippingModel,
    saleProducts:[]
  };
  metadata = {
    ingles:false,
  }
  order = {
    id:'',
    status:'',
    guide:'',
    observations:''
  }
  statusList = [
    'Nuevo',
    'Confirmado',
    'En camino',
    'Entregado'
  ];
  saleProductsList = [];
  guidesList = [];

  object = JSON.parse(JSON.stringify(this.saleModel));
  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminSalesDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.object = this.data;
    // console.log(this.data);
  }

  /**funcionalidad para obtener las guias asignadas
  */
  getGuides(){
    this.session.postRequest("guide:findAllBySale",{sale:{id:this.object.id}}).subscribe((data:any)=>{
      console.log("Guidas:::::::",data.object.instanceList);
      this.guidesList = data.object.instanceList;
      if(data.object.instanceList.length > 0){
        this.order.guide = data.object.instanceList[0].guide;
      }
    },error=>{
      console.log(error);
    });
  }

  ngOnInit(): void {
    //obetenesmos los datos de la orden
    this.session.postRequest("order:findBySale",{id:this.object.id}).subscribe((data:any)=>{
      this.order = data.object;
      this.getProductlist();
      this.getGuides();
      // console.log("Order:::::::",this.order);

    },error=>{
      console.log(error);
    });
  }

  /**funcionalidad para obetenr el producto
  */
  getProduct(object){
    this.session.postRequest("product:get",{id:object.product_id}).subscribe((data:any)=>{
      data.object.image = 'https://casamexcalli.com/public/uploads/'+data.object.image;
      object.product = data.object;
    },error=>{
      console.log(error);
    })
  }
  /**metodo para obetner los productos
  */
  getProductlist(){
    for(let i = 0; i < this.object.products.length; i++){
      this.getProduct(this.object.products[i]);
    }
    this.saleProductsList = this.object.products;
  }

  orderChangeStatus(){
    // console.log(this.order);
    this.loadingService.show(true,"Espere un momento...");
    this.session.postRequest("order:changeStatus",this.order).subscribe((data:any)=>{
      this.snackBar.open("El estatus del pedido cambio exitosamente.", '', {duration: 3000,});
      this.loadingService.hide();
    },error=>{
      console.log(error);
      this.loadingService.hide();
    })
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'category:001';
    }
    this.dialogRef.close(object);
  }


}
