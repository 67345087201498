import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { ClientBookingComponent } from '../client-booking/client-booking.component';

@Component({
  selector: 'app-client-why-house-mexalli',
  templateUrl: './client-why-house-mexalli.component.html',
  styleUrls: ['./client-why-house-mexalli.component.css']
})
export class ClientWhyHouseMexalliComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }



  /**Modal para reservar
  */
  bookingModal(){
    this.dialog.open(ClientBookingComponent, {
      width: '60rem',
      // data: uuid
    }).afterClosed().subscribe(result => {
    });
  }
}
