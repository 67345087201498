<app-client-menu></app-client-menu>
<div class="row bg-prod">
  <div class="col-12 pt-5">
    <ngb-carousel class="mt-5" [showNavigationIndicators]="false">
      <ng-template ngbSlide >
        <div class="picsum-img-wrapper">
          <div class="row justify-content-center">
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/Barrio-de-Jalatlaco.png" alt="logo" style="width:10rem;">
            </div>
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/CAPOTLAN-BLANCO.png" alt="logo" style="width:10rem;">
            </div>
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/colores.png" alt="logo" style="width:10rem;margin-top:1rem;">
            </div>
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/LA-VENIA.png" alt="logo" style="width:10rem;margin-top:1rem;">
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide >
        <div class="picsum-img-wrapper">
          <div class="row justify-content-center">
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/Logo-rabon.png" alt="logo" style="width:10rem;">
            </div>
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/MALA-IDEA-Coyote.png" alt="logo" style="width:10rem;">
            </div>
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/MONO-BLANCO.png" alt="logo" style="width:10rem;margin-top:1rem;">
            </div>
            <div class="col-md-3 col-6" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/PONTE-CHINGON.png" alt="logo" style="width:10rem;margin-top:1rem;">
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template ngbSlide >
        <div class="picsum-img-wrapper">
          <div class="row justify-content-center">
            <div class="col-md-4 col-12" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/RIO-REVUELTO.png" alt="logo" style="width:15rem;margin:0 auto;">
            </div>
            <div class="col-md-4 col-12 pt-2" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/SabarDios_logo.png" alt="logo" style="width:15rem;margin:0 auto;padding-top:1rem;">
            </div>
            <div class="col-md-4 col-12 pt-2" style="display: flex;align-items: center;">
              <img src="assets/img/home-prod/Yuu-Baal.png" alt="logo" style="width:15rem;margin:0 auto;padding-top:1rem;">
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-md-10 col-12 mt-3">
    <div class="row justify-content-center">
      <mat-form-field class="col-12 col-md-10">
        <input matInput placeholder="Buscar" [(ngModel)]="productMin.filter" (ngModelChange)="search()" >
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="row justify-content-center">
      <div class="h5" style="text-align: center;">
        <div class="btn_maker" (click)="showAllProducts()">
          <span class="badge" [ngClass]="{'bg-dark':productMin.maker.id == '', 'badge-light':productMin.maker.id != ''}">TODOS</span>&nbsp;
        </div>
        <div class="btn_maker" *ngFor="let object of makers" (click)="findProductByMaker(object)">
          <span class="badge" [ngClass]="{'bg-dark':object.select , 'badge-light':!object.select}">{{object.name}}</span>&nbsp;
        </div>
      </div>
    </div>
    <div class="row">
      <!-- <mat-tab-group (selectedTabChange)="changeCategory($event);" mat-align-tabs="center">
        <mat-tab label="{{category.name}}" *ngFor="let category of categories">
        </mat-tab>
      </mat-tab-group> -->
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-4 col-6 p-2 text-center" *ngFor="let object of list" >
          <mat-card class="col-12 m-0 p-0" style="overflow: hidden; height: 24rem; cursor: pointer;">
            <div class="img-card col-12 p-0">
              <div class="bandera" *ngIf="object.discount">
                <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
              </div>
              <img src="{{object.image}}" alt="{{object.name}}" class="image" routerLink="/client/product/detail/{{object.id}}">
              <button type="button"  [ngClass]="object.like ? 'boton-like':'boton-dislike'" (click)="object.like=!object.like"  [matTooltip]="object.like ? 'Quitar de favoritos':'Agregar a favoritos'"></button>
            </div>
            <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
              <ul class="color">
                <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
                  <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                  <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
                </li>
              </ul>
            </div>
            <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/detail/{{object.id}}">
              <p class="producto">{{object.name}}</p>
              <p class="marca mb-2 mt-2">{{object.maker.name}}</p>
            </div>
            <div class="row justify-content-center" style="width: 100%; margin: 0px;">
              <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}}</h3>
            </div>
          </mat-card>
          <a class="text-dark h5 fw-bold mt-2" style="width: 100%; cursor:pointer;" (click)="addToShoppingCard(object)" >AÑADIR AL CARRITO &nbsp;<span class="material-icons">shopping_cart</span></a>
        </div>
      </div>
    </div>
    <div class="row justify-content-center m-0">
      <button  mat-button color="primary" class="col-md-4 col-12" *ngIf="metadata.getMoreProducts" (click)="showMoreProducts();">VER MÁS PRODUCTOS</button>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
