import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from './../../service/session/session.module';
import { LoadingService } from './../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  complexForm: FormGroup;

  homeModel = {
    id:'',
    section_our_img:'',
    section_our_title:'',
    section_our_content:''
  }
  metadata = {
    ingles:false,
    img:{
      file: '',
      type: '',
      name: '',
    },
    uploadted:false
  }

  object = JSON.parse(JSON.stringify(this.homeModel));
  makerMin = {
    max: 400,
    page: 1,
  }
  makerList = [];
  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar) {
    this.complexForm = fb.group({
      'section_our_title': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.loadingService.wait();
     // cargamos los datos de la BD
     this.session.postRequest('home:get',{id:1}).subscribe((data:any)=>{
       if(data.object){
         this.object = JSON.parse(JSON.stringify(data.object));
         this.metadata.img.name = this.object.section_our_img.substring(this.object.section_our_img.lastIndexOf("/")+1, this.object.section_our_img.length);
         this.metadata.uploadted = true;
       }
       this.loadingService.hide();
     },
     (error)=>{
       this.loadingService.hide();
       console.log('Error: home:get',error)
     })
  }


    /**
     * funcion para guardar una unidades de medida
     */
    send(object){
      this.loadingService.show(true,'Guardando registro...');
      this.session.postRequest("home:update",object).subscribe(
        (data:any) => {
          this.snackBar.open('home se guardo exitosamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
        },
        error => {
          console.log("Error: home:update",error);
          this.loadingService.hide();
        }
      );
    }


    /**función para cargar una imagen
    */
    loadImg(){
      const fileUpload = this.fileGet.nativeElement;
      fileUpload.onchange = () => {
       this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
       this.metadata.img.type = fileUpload.files[0].type;
       let file = fileUpload.files[0];
       console.log("FILE",fileUpload.files[0]);
       this.metadata.img.file = file;
       console.log("FILE",this.metadata.img);
      };
      fileUpload.click();
    }

    /** función para subir una imagen al servidor
    */
    async uploadImg(){
      try{
        this.loadingService.show(true,"Espere un momento...")
        const formData = new FormData();
        formData.append('file', this.metadata.img.file, this.metadata.img.name);
        this.session.uploadFile("home:upload",formData).subscribe(async(data:any)=>{
          this.object.section_our_img = data.object.name;
          this.metadata.uploadted = true;
          this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
          this.loadingService.hide();
        },error=>{
          console.log("home:upload",error);
          this.loadingService.hide();
        })
      }catch(e){
        console.log(e);
        this.loadingService.hide();
      }

    }

    /**función para quitar una iimagen
    */
    deleteImg(){
      this.metadata.img.file = '';
      this.metadata.img.name = '';
      this.metadata.uploadted = false;
    }

}
