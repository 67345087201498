<app-admin-toolbar></app-admin-toolbar>
<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Mescalero del Mes</h2>
      </div>
      <div class="col-3 text-right">
      </div>
      <p class="col-12">Ingrese los datos requeridos del formulario.</p>
    </div>

    <div class="row justify-content-center">
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Titulo</mat-label>
        <input matInput placeholder="Titulo" name="nombre" [formControl]="complexForm.controls['section_our_title']" [(ngModel)]="object.section_our_title" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['section_our_title'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <div class="col-10 mb-3">
        <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
        <div class="cardImg">
          <span *ngIf="metadata.img.name == ''">Agregar imagen de fondo</span>
          <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>
          <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
          <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
        </div>
      </div>

      <div class="col-10">
        <ckeditor [(ngModel)]="object.section_our_content" editorUrl="https://casamexcalli.com/assets/ckeditor/ckeditor.js"></ckeditor>
      </div>


      <div class="col-10 mt-3">
        <button mat-raised-button color="primary" style="width:100%;"  (click)="send(object);" [disabled]="complexForm.invalid">Guardar</button>
      </div>
    </div>


  </div>
</div>
