import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { SessionService } from './../../service/session/session.service';
import { LoadingService } from './../../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { StorageTool } from './../../service/session/storage.tool';
import {Location} from '@angular/common';
import * as moment from 'moment';
moment.locale('es-us');
declare var paypal;

@Component({
  selector: 'app-client-payment',
  templateUrl: './client-payment.component.html',
  styleUrls: ['./client-payment.component.css']
})
export class ClientPaymentComponent implements OnInit {
  @ViewChild('myForm') ngForm;
  secondStepFrom: FormGroup;
  thirdStepFrom: FormGroup;
  firstStepForm: FormGroup;
  shippingModel = {
    id:'',
    cp:'',
    street:'',
    colony:'',
    city:'',
    state:'',
    observations:'',
    delivery:'',
    sale:{
      id:''
    }
  }
  saleModel = {
    id:'',
    folio:'',
    clientName:'',
    clientSocialReason:'',
    clientRfc:'',
    clientPhone:'',
    clientEmail:'',
    platform:'web',
    typePayment:'',
    paymentId:'',
    discount:0,
    tax:0,
    delivery:0,
    subtotal:0,
    total:0,
    status:'Pendiente',
    terms: true,
    privacy:true,
    user:{
      id:0
    },
    shipping:this.shippingModel,
    saleProducts:[],
    delivery_quantity:0,
    weight:0
  };

  saleProductsModel = {
    id:'',
    quantity:1,
    unit_price:0,
    discount:0,
    tax:0,
    subtotal:0,
    total:0,
    sale_id:'',
    product_id:'',
    description:'',
    product:{}
  };

  paypalModel = {
    id:'',
    platform:'',
    product_name:'',
    paypal_id:'',
    intent:'',
    state:'',
    environment:'',
    sale_id:''
  }
  mexpagoModel = {
    url:'https://mexpago.com/app/pagoOnline',
    // url:'https://dev.mexpago.com/app/pagoOnline',
    monto:0,
    noTransaccion:'',
    llave:'',
    fecha:'',
    articulos:'',
    precargaDatos:'',
    enviarCorreo:false,
    infoComercio:true,
    lenguaje:'es',
  }
  mexpagoDB = {
    id:'',
    folioMexPago:'',
    numeroTransaccion:'',
    pago:'',
    monto:''
  }
  paymentMethods = [{
    icon:'/assets/img/paypal.png',
    name:'PayPal',
    selected:'PayPal'
  },{
    icon:'/assets/img/tarjeta.png',
    name:'Tarjeta de Débito/Crédito',
    selected:'Mexpago'
  }];
  object = JSON.parse(JSON.stringify(this.saleModel));
  deliveryMin = {
    max: 100,
    page: 1,
    status:true
  }
  deliveryList = [];
  saleProductsList = [];
  metadata = {
    sender:'',
    language:'spanish',
    freeDelivery:false,
    deliveryPromo:false
  }
  constructor(
    public session:SessionService,
    public loading:LoadingService,
    public fb: FormBuilder,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar:MatSnackBar,
    public dialog: MatDialog,
    public storage: StorageTool,
    private _location: Location
  ) {
    this.storage.getAkeyFromStorage('spanish').then((data:any)=>{
      if(data){
        this.metadata.language = data;
      }
    })
    this.firstStepForm = fb.group({
      'phone': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.required],
      'name': [null, Validators.required],
      'address': [null, Validators.required],
      'colony': [null, Validators.required],
      'cp': [null, Validators.required],
      'city': [null, Validators.compose([Validators.required])],
      'state': [null, Validators.required],
    });
    this.secondStepFrom = fb.group({
      'sender': [null, Validators.compose([Validators.required])],
    });
    this.thirdStepFrom = fb.group({
      'paymentMethod': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    //cargamos los productos del carrito de compras
    this.storage.getAkeyFromStorage('shoppingCart').then(async(data:any)=>{
      if(data){
        this.object.weight = 0;
        let countPromo = 0;
        for(let item of data){
          let aux = JSON.parse(JSON.stringify(this.saleProductsModel));
          aux.quantity = item.quantity;
          aux.unit_price = item.product.price;
          aux.product_id = item.product.id;
          aux.product = item.product;
          if(item.productPrice.id != ''){
            aux.description = item.product.name +' | '+  item.productPrice.capacity +' | '+item.productPrice.wood+' | '+item.productPrice.varnish;
          }else{
            aux.description = item.product.name
          }
          if(item.product.freeDelivery){
            this.metadata.freeDelivery = true;
          }
          this.saleProductsList.push(aux);
          //calculamos el peso total de lo que se comprara
          this.object.weight += (item.product.weight * item.quantity);
          //verificamos si activamos promoción 3 de 750 envio a 100
          if(item.product.name.toUpperCase().indexOf('750ML') > 0){
            countPromo = (countPromo*1) + (item.quantity*1);
          }
          if(countPromo >= 3){
            this.metadata.deliveryPromo = true;
          }
        }
        await this.getDeliveries();

        //asigamos los precios de envio a las diferentes paqueterias
        for(let i = 0; i < this.deliveryList.length; i++){
          let quantityGuiasTemp = Math.ceil(this.object.weight/this.deliveryList[i].max_weight);
          this.deliveryList[i].quantityGuide =  quantityGuiasTemp;
          this.deliveryList[i].adjPrice = this.deliveryList[i].price * quantityGuiasTemp;
          if(this.metadata.deliveryPromo){
            this.deliveryList[i].adjPrice = this.deliveryList[i].adjPrice - (this.deliveryList[i].price * 0.5);
          }
        }

        //calculamso los costos de envio para estos articulos

        this.calculateTotals();
      }
    }).catch(e=>{
      console.log(e);
    })
  }

  /*funcionalidad para seleccionar una mensajeria
  */
  deliverySelected(){
    for(let item of this.deliveryList){
      if(this.metadata.sender == item.id){
        this.object.delivery = parseFloat(item.adjPrice);
        this.object.shipping.delivery = item.name;
        this.object.delivery_quantity = item.quantityGuide;
      }
    }
    if(this.metadata.freeDelivery){
      this.object.delivery = 0;
    }
    this.calculateTotals();
  }

  /*funcionalidad para calcular el total
  */
  calculateTotals(){
    this.object.tax = 0;
    this.object.subtotal = 0;
    this.object.total = 0;
    for(let i = 0 ; i < this.saleProductsList.length; i++){
      this.saleProductsList[i].discount = 0;
      this.saleProductsList[i].subtotal = (this.saleProductsList[i].quantity * this.saleProductsList[i].unit_price)/1.16;
      this.saleProductsList[i].tax = this.saleProductsList[i].subtotal * 0.16;
      this.saleProductsList[i].total = this.saleProductsList[i].subtotal + this.saleProductsList[i].tax;
      // realiza sumatoria de totales
      this.object.subtotal = parseFloat(this.object.subtotal) + parseFloat(this.saleProductsList[i].total);
    }
    this.object.subtotal = (this.object.subtotal + (this.metadata.freeDelivery ? 0 : this.object.delivery) - this.object.discount) / 1.16;
    this.object.tax = this.object.subtotal * 0.16;
    this.object.total = this.object.subtotal + this.object.tax;
    console.log("sale",this.object);
    console.log("productSale",this.saleProductsList);

  }


  /**función para obtener el listado de mensajeria
  */
  getDeliveries(){
    return new Promise((resolve,reject)=>{
      this.session.postRequestAnonimus("delivery:findAllByStatus",this.deliveryMin).subscribe((data:any)=>{
        this.deliveryList = data.object.instanceList;
        resolve(true);
      },error=>{
        console.log("error:delivery:findAllByStatus",error);
        reject(error);
      })
    })

  }

  /************ Funciones de Pagos *******************/
    /** función para cambiar el methodo de pago
    */
   async changePaymentMethod(){
     //completamos los datos de productos para enviarlos a la bd
     this.object.saleProducts = [];
     for(let item of this.saleProductsList){
       let aux = JSON.parse(JSON.stringify(item));
       delete aux.product;
       this.object.saleProducts.push(aux);
     }
      switch(this.object.typePayment){
        case 'PayPal':{
          // this.metadata.msj = '';
          this.object.payment = JSON.parse(JSON.stringify(this.paypalModel));
            setTimeout(()=>{
              paypal.Buttons({
               createOrder: (data, actions) => {
                 // This function sets up the details of the transaction, including the amount and line item details.
                 return actions.order.create({
                   purchase_units: [{
                     amount: {
                       value: parseFloat(this.object.total.toString()).toFixed(2)
                     }
                   }]
                 });
               },
               onApprove: (data, actions) => {
                 // console.log("dataPaypal",data);
                 this.loading.show(true,"Espere un momento...");
                 return actions.order.capture().then(async(details)=> {
                   // console.log("paypalDetalle",details)
                  // This function shows a transaction success message to your buyer.
                  if(details.status == 'COMPLETED'){

                    // Successfully paid -- bindeamos los datos para envia a la BD
                    this.object.payment.platform = 'WEB';
                    this.object.payment.product_name = details.payer.payer_id;
                    this.object.payment.paypal_id = details.id;
                    this.object.payment.intent = details.intent;
                    this.object.payment.state = details.status;
                    this.object.payment.environment = 'WEB';
                    // enviamos los datos a la BD;
                    try{
                      this.object.status = "Pagado";
                      let sale:any = await this.sendSale();
                      // this.metadata.msj ="Gracias por tu compra";
                      console.log("Enviamos el objeto",this.object);
                      // this.openSearchSale(sale.folio,sale.email);
                      this.loading.hide();
                      this.router.navigateByUrl('/client/search/'+this.object.clientPhone+'?folio='+sale.folio);
                      this.storage.remove('shoppingCart');
                    }catch(e){
                      // this.metadata.msj = "Hubo un error al procesar los datos, guarde la siguiente informacion y contacte con TConilla, paypal_id: " + this.object.payment.paypal_id;
                      // this.router.navigate(['/']);
                      this.loading.hide();
                    }

                  }else{
                    // this.metadata.msj = "Error: Fallo en el modo de pago, intento con otra forma de pago.";
                  }
                });
              }
             }).render('#paypal-button-container');
           }, 200);
          break;
        }
        case 'Mexpago':{
          console.log("mexpago")
          // this.metadata.msj = '';
          this.mexpagoModel.monto = this.object.total;
          this.mexpagoModel.fecha = moment().format('YYYY-MM-DD hh:mm:ss');
          this.mexpagoModel.llave = 'eyJsbGF2ZSI6ImE4NWJhYTU4LTllMjUtNDU3NC1hZjM0LWM2OGNiZDUzMGIxOSIsImlkT25saW5lIjoiMThkZDFlMTAtZGNiMi00NmRiLTlhMWMtZmMwZDYyYjFlYjAyIn0=';
          this.mexpagoModel.lenguaje = 'es';
          let precargaDatos = {
            nombre:'',
            apPaterno:'',
            apMaterno:'',
            celular:'',
            correo:''
          };
          precargaDatos.nombre = '';
          precargaDatos.apPaterno = '';
          precargaDatos.apMaterno = '';
          precargaDatos.celular = this.object.clientPhone;
          precargaDatos.correo = this.object.clientEmail;
          this.mexpagoModel.precargaDatos = JSON.stringify(precargaDatos);
          this.mexpagoModel.enviarCorreo = false;
          this.mexpagoModel.infoComercio = true;
          let articulos = {articulos:[]};
          for(let item of this.saleProductsList){
            let aux = {
              descripcion:item.product.name,
              monto:Math.round((item.total)*100)/100,
            }
            articulos.articulos.push(aux);
          }
          //cargamos envio
          articulos.articulos.push({
            descripcion:'Envio',
            monto:Math.round((this.object.delivery)*100)/100,
          });
          console.log(articulos)
          this.mexpagoModel.articulos = JSON.stringify(articulos);
          this.mexpagoModel.noTransaccion = 'TC-'+Math.floor(Math.random() * (9999999-100000) + 100001).toString();
          this.object.payment = JSON.parse(JSON.stringify(this.mexpagoDB));
          this.object.payment.numeroTransaccion = this.mexpagoModel.noTransaccion;
          this.object.status = "Pendiente";
          break;
        }
        default:{
          console.log("Error, selecciona un metodo de pago");
          break;
        }
      }
    }

    /** función para enviar lso datos de venta al backend
    */
    sendSale(){
      return new Promise((resolve,reject)=>{
        this.session.postRequest("sale:update",this.object).subscribe((data:any)=>{
          resolve(data.object);
        },error=>{
          reject(error);
        })
      })
    }

    /**fucnionalidad para enviar venta de mexpago
    */
    async sendToPay(){
      this.loading.show(true,"Espere un momento...");
      let sale:any = await this.sendSale();
      this.storage.remove('shoppingCart');
      console.log("Mexpago",this.object);
      this.loading.hide();
      this.ngForm.nativeElement.submit();
    }

    /*Funcion para regresar*/
    backClicked() {
    this._location.back();
  }

}
