import { Component, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  enabled = false;
  msg = '';
  whiteEnable = false;
  constructor(private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService.loadingChange.subscribe((value)=>{
      this.enabled = value.visible;
      this.msg = value.message;
    })
    this.loadingService.loadingWhite.subscribe((value)=>{
      this.whiteEnable = value.visible;
    })
  }
}
