import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminGuidesUpdateComponent } from './admin-guides-update/admin-guides-update.component';
import { AdminGuidesUploadexcelComponent } from './admin-guides-uploadexcel/admin-guides-uploadexcel.component';

@Component({
  selector: 'app-admin-guides',
  templateUrl: './admin-guides.component.html',
  styleUrls: ['./admin-guides.component.css']
})
export class AdminGuidesComponent implements OnInit {
  guideMin = {
    max: 20,
    page: 1,
  }
  list = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 20, 50, 100],
  };
  pageEvent: PageEvent;
  constructor(
    private session: SessionService,
    public loadding:LoadingService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.getGuide();
  }

  /**función para obtener el listado de categorias
  */
  getGuide(){
    this.session.postRequest("guide:list",this.guideMin).subscribe((data:any)=>{
      this.list = data.object.instanceList;
      this.paginator.total = data.object.total;
    },error=>{
      console.log("error:guide:list",error);
    })
  }

  /** funcionalidad para crear una guia
  */
  update(uuid){
    const dialogRef = this.dialog.open(AdminGuidesUpdateComponent, {
      width: '50%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getGuide();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  /** funcionalidad para crear una importar un excel de guias
  */
  uploadGuide(uuid){
    const dialogRef = this.dialog.open(AdminGuidesUploadexcelComponent, {
      width: '50%',
      data: uuid,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getGuide();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

  delete(object){
    this.loadding.show(true,"Espero un momento..");
    this.session.postRequest("guide:delete",object).subscribe((data:any)=>{
      for(let  i = 0; i < this.list.length; i++){
        if(this.list[i].id == object.id){
          this.list.splice(i,1);
        }
      }
      this.loadding.hide();
    },error=>{
      this.loadding.hide();
      console.log("error:guide:delete",error);
    })
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.guideMin.max = event.pageSize;
    this.guideMin.page = event.pageIndex + 1;
    this.getGuide();
  }

}
