import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../../directive/loading/loading.service';
export interface images {
  img:{
    file: string,
    type: string,
    name: string,
  },
  uploadted:boolean
}
@Component({
  selector: 'image-product',
  templateUrl: './image-product-directive.html',
  styleUrls: ['./image-product-directive.css'],
  changeDetection: ChangeDetectionStrategy.Default,

})

export class ImageProductDirective implements OnInit  {
  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  @Input('mFile') metadata: images;

  @Input('uploadCode') uploadCode: string;

  constructor(protected session:SessionService, public snackBar: MatSnackBar,
    public loading:LoadingService
  ) {
    console.log("FILE",this.metadata);
  }

  ngOnInit() {}

  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
      let u = this.uuidv4();
     this.metadata.img.name = u+fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     this.metadata.img.file = file;
     console.log("FILE",this.metadata.img);
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loading.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      this.session.uploadFile(this.uploadCode,formData).subscribe(async(data:any)=>{
        this.metadata.uploadted = true;
        this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
        this.loading.hide();
      },error=>{
        console.log(this.uploadCode,error);
        this.loading.hide();
      })
    }catch(e){
      console.log(e);
      this.loading.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

  uuidv4() {
    let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    let guid = [u.substr(0,8), u.substr(8,4), '4000-8' + u.substr(13,3), u.substr(16,12)].join('_');
    return guid;
  }


}
