<app-admin-toolbar></app-admin-toolbar>
  <div class="row justify-content-center" style="margin-top: 50px;">
    <div class="col-12 col-md-10 cardTono">
      <div class="row justify-content-between">
        <div class="col-8">
          <h2 class="mb-2">Pedidos</h2>
        </div>
        <div class="col-3 text-right">
        </div>
      </div>
      <mat-tab-group mat-align-tabs="start" (selectedTabChange)="changeTabSale($event);">
        <mat-tab label="Pagado">
          <mat-tab-group mat-align-tabs="start" (selectedTabChange)="filtaerTabBySaleAndOrderStatus($event);">
            <mat-tab label="Nuevo"></mat-tab>
            <mat-tab label="Confirmado"></mat-tab>
            <mat-tab label="En camino"></mat-tab>
            <mat-tab label="Entregado"></mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab label="Pendiente"></mat-tab>
      </mat-tab-group>
      <table class="table table-general">
        <thead class="header-table">
          <tr>
            <th>Folio</th>
            <th>Cliente</th>
            <th>Telefono</th>
            <th>Mensajeria</th>
            <th>Monto</th>
            <th>Estatus</th>
            <th>Acciones</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let object of saleList; let i = index;">
            <td>{{object.folio}}</td>
            <td>{{object.clientName}}</td>
            <td>{{object.clientPhone}}</td>
            <td>{{object.shipping.delivery}}</td>
            <td>{{object.total | currency:'CAD':'symbol-narrow' }}</td>
            <td>
              <span class="labelEnabled">{{object.status}}</span>
            </td>
            <td>
              <button mat-icon-button color="accent"  style="font-size: smaller;" matTooltip="Detalles" (click)="openSale(object);"><mat-icon>chrome_reader_mode</mat-icon></button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
        <div class="col-6 ">
          <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
