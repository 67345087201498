<app-client-menu></app-client-menu>
<!-- TITULO -->
<div class="row justify-content-center m-0" style="margin-top: 70px !important">
    <h2 style="padding-top: 35px; font-size: 1.5rem;">Gracias por tu preferencia.</h2>
</div>
<div class="row justify-content-center m-0" style="padding: 20px 0px;">
  <div class="col-11 col-md-10 col-lg-10">
    <div style="padding: 10px; padding-top: 0px;">
      <mat-card style="background-color: #fdfdfd;">
        <div class="alert alert-success" role="alert" *ngIf=" object.status == 'Pagado'"> Pagado </div>
        <div class="alert alert-danger" role="alert" *ngIf=" object.status == 'Pendiente'">Pago pendiente</div>
        <h4>Hola {{object.clientName}},</h4>
        <p>Acontinuación se detallan los datos de tu compra.</p>
        <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
          <li>Folio: {{object.folio}}</li>
          <li>Cliente: {{object.clientName}}</li>
          <li>Correo: {{object.clientEmail}}</li>
          <li>Teléfono: {{object.clientPhone}}</li>

        </ul>
        <p class="col-12">La guia asignada a la compra es la siguiente.</p>
        <ul class="col-12">
          <li *ngFor="let object of guidesList">
            <strong>{{object.guideModel.delivery.name}}: </strong><a href="https://www.redpack.com.mx/es/rastreo/?guias={{object.guide}}" *ngIf="object.guideModel.delivery.name == 'REDPACK'">{{object.guide}}</a>
           </li>
        </ul>
        <small>*Se le notificará por correo electrónico cuando su paquete esté listo para reparto.</small>
        <div class="row">
          <div class="col-6">
            <!-- <hr><hr> -->
            <p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Listado de productos.</p>
            <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
              <li style="margin-bottom: 15px;display: flex;" *ngFor="let object of saleProductsList">
                <div class="imgTitle">
                  <span data-action="cart-can" class="badge rounded-circle" style="position: absolute; background: #3ab77c; color: #fff; font-weight: 100; font-size: 0.83rem; top: 5px; left:5px;">{{object.quantity}}</span>
                  <div class="imgAvatar" *ngIf="object.product">
                    <img src="{{object.product.image}}" alt="{{object.product.name}}">
                  </div>
                </div>
                <div class="titlePayment" *ngIf="object.product">
                  {{object.description}}
                </div>
                <div class="priceTitle">
                  {{object.total | currency:'MXN':'symbol-narrow'}}
                </div>
              </li>
            </ul>
            <hr>

          </div>
          <div class="col-6">

          </div>
          <div class="col-3" style="margin: 0px; padding: 0px 25px;">
            <ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
              <li style="margin-bottom: 15px;">ENVÍO</li>
              <li style="margin-bottom: 15px; font-weight: bold;">TOTAL</li>
            </ul>
          </div>
          <div class="col-3" style="margin: 0px; padding: 0px 15px; text-align: end;">
            <ul  style="list-style:none; padding: 0px;">
              <li style="margin-bottom: 15px;">{{object.delivery | currency:'MXN':'symbol-narrow'}}</li>
              <li><h6 class="" style="font-size: 14pt; font-weight: bold;">{{object.total | currency:'MXN':'symbol-narrow'}}</h6></li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="col-11 col-md-10 col-lg-10">


  </div>
</div>

<app-client-footer></app-client-footer>
