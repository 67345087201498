<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nueva Marca</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-5">
      <div class="col-10">
        <mat-slide-toggle [labelPosition]="'before'" [(ngModel)]="object.status" >Activado</mat-slide-toggle>
      </div>
      <mat-form-field class="col-12 col-md-8" appearance="outline">
        <mat-label>&nbsp;Nombre</mat-label>
        <input matInput placeholder="Nombre" name="mName" [(ngModel)]="object.name" [formControl]="complexForm.controls['name']">
        <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>&nbsp;Descripción</mat-label>
        <textarea matInput placeholder="Descripción" name="mdescription" [(ngModel)]="object.description" [formControl]="complexForm.controls['description']"></textarea>
        <mat-error *ngIf="complexForm.controls['description'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>

      <div class="col-12">
        <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
        <div class="cardImg">
          <span *ngIf="metadata.img.name == ''">Agregar imagen</span>
          <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>
          <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
          <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
        </div>
      </div>

    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;" [disabled]=" complexForm.invalid "  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
