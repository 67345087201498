<app-client-menu></app-client-menu>

<div class="row justify-content-center m-0" #top style="margin-top: 120px !important;">
  <div class="col-11 col-md-10 col-lg-10" style="text-aling:right;">
  <button  class="btn-verde" name="button" style="float: right;" (click)="backClicked();"> REGRESAR</button>
  </div>
</div>

<div class="row justify-content-center m-0" style="padding-bottom:60px;">
  <div class="col-11">
    <div class="row mt-2 mb-1 cardProduct">

      <div class="col-12 col-md-7">
        <div class="banderaMin" *ngIf="object.discount">
          <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
        </div>
          <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
      </div>

      <div class="col-12 col-md-4" >
        <div class="row m-0" style="padding: 8px;">
          <img src="assets/img/formas-pago.png" alt="" style="width:100%;">
        </div>
        <div class="row" style="background:#f5f5f5;">
          <div class="col-11" style="">
            <h5 style="line-height: 2rem; color: #c6945d;">{{object.name}}</h5>
          </div>
          <div class="col-12" style="text-align: start;">
            <h6 style="font-family: 'Eina-Bold';"> <del style="color:#75938d;" *ngIf="object.discount">{{metadata.unitPriceDiscount | currency:'MXN':'symbol-narrow'}}</del> &nbsp; <span style="color:#243431;">{{metadata.unitPrice | currency:'MXN':'symbol-narrow'}} &nbsp;</span> </h6>
          </div>
          <div class="col-12 ">
            <p style="color:#7a7a7a; font-size:.7rem;">{{object.description}}</p>
          </div>
          <div class="col-12 ">
            <p style="color:#7a7a7a; font-size:.7rem;" *ngIf="object.freeDelivery">* ENVIO GRATIS </p>
          </div>

          <div class="col-12 col-md-8 form-group content-select" style="display: flex;" *ngIf="object.multiplePrice">
            <label for="exampleFormControlSelect1" style="margin-top: 4px; font-size: .7rem;"></label>
            <select class="custom-select" name="quantity" [(ngModel)]="metadata.capacity" style="font-size: .8rem; margin-left:5px;" (ngModelChange)="capacitySelected()">
              <option *ngFor="let object of object.attributesList" [value]="object.capacity">{{object.capacity}}</option>
            </select>
            <i></i>
          </div>
          <div class="col-12" *ngIf="object.multiplePrice">
            <p style="font-size:.8rem; margin:0px;">Madera</p>
            <div class="row justify-content-star colores" style="width: 100%; margin: 0px;">
              <ul class="color" style="width: 100%; margin: 0px;">
                <li class="color" style="cursor:pointer;" *ngFor="let object of metadata.productPriceList" matTooltip="{{object.wood}}" (click)="selectedProductPrice(object);">
                  <div class="circulo" [ngClass]="object.selected ? 'circuleActivate' : '' " *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div>
                  <p></p>
                  <div class="circulo" [ngClass]="object.selected ? 'circuleActivate' : '' " *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div>
                  <p>{{object.wood}} | {{object.varnish}}</p>
                </li>

              </ul>
            </div>
          </div>

          <div class="col-12" *ngFor="let object of productAttributeList">
            <p style="font-size:.8rem; margin:0px;">{{object.name}}</p>
            <div class="row justify-content-star colores" style="width: 100%; margin: 0px;">
              <ul class="color">
                <li class="color" *ngFor="let object of object.productAttributeValues" matTooltip="{{object.value}}">
                  <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
                  <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
                </li>
                <!-- <li class="color"><div class="circulo gris"></div><p>Caoba</p></li> -->
                <!-- <li class="color"><div class="circulo beige"></div><p>Roble</p></li> -->
              </ul>
            </div>
            <!-- <p style="font-size:.8rem; margin:0px;">Barniz</p>
            <div class="row justify-content-star colores" style="width: 100%; margin: 0px;">
              <ul class="color">
                <li class="color"><div class="circulo natural"></div><p>Natural</p></li>
                <li class="color"><div class="circulo maple"></div><p>Maple</p></li>
              </ul>
            </div> -->
          </div>
          <div class="col-12 col-md-8 form-group content-select" style="display: flex;">
            <label for="exampleFormControlSelect1" style="margin-top: 4px; font-size: .7rem;">CANTIDAD</label>
            <select class="custom-select" name="quantity" [(ngModel)]="metadata.quantity" style="font-size: .8rem; margin-left:5px;">
              <option *ngFor="let object of fakeQuantity " [value]="object">{{object}}</option>
            </select>
            <i></i>
          </div>
          <!-- <div class="col-12">
            <p style="font-size: .8rem; margin: 0px;">Entrega estimada</p>
            <p style="font-size: .8rem; color:#60c56e;">De 2 a 5 días hábiles</p>
          </div> -->
        </div>

          <div class="row justify-content-center m-0 mt-1" >
            <div class="col-8">
                <button type="button" name="button" class="btn-negro" style="width: 100%;" (click)="addProductToShoopingCart();">AÑADIR AL CARRITO</button>
            </div>
          </div>

      </div>
    </div>
  </div>
</div>



<!-- ÚLTIMOS PRODUCTOS VISTOS-->
<div class="row justify-content-center m-0 mb-4">
  <div class="col-12">
    <h6 class="titulo">ÚLTIMOS PRODUCTOS VISTOS </h6>
  </div>
</div>

<div class="container">
  <div class="row justify-content-star m-0">
    <div class="col-lg-3 col-md-4 col-6 p-2" *ngFor="let object of productLastSee" routerLink="/client/product/detail/{{object.id}}" (click)="scrollTo(top)">
      <div class="col-12 m-0 p-0" style="overflow: hidden; height: 20rem; cursor: pointer; border: 1px solid #e0e0e0; background:white;">
        <div class="img-card col-12 p-0">
          <div class="bandera" *ngIf="object.discount">
            <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
          </div>
          <img src="{{object.image}}" alt="{{object.name}}" class="image" routerLink="/client/product/detail/{{object.id}}">
          <button type="button"  [ngClass]="object.like ? 'boton-like':'boton-dislike'" (click)="object.like=!object.like"  [matTooltip]="object.like ? 'Quitar de favoritos':'Agregar a favoritos'"></button>
        </div>
        <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
          <ul class="color">
            <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
              <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
              <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
            </li>
          </ul>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/detail/{{object.id}}">
          <p class="producto">{{object.name}}</p>
          <p class="marca" *ngIf="object.maker">{{object.maker.name}}</p>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;">
          <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- TITULO PRODUCTOS-->
<div class="row justify-content-center m-0 mb-4">
  <div class="col-12">
    <h6 class="titulo">PRODUCTOS RELACIONADOS </h6>
  </div>
</div>

<div class="container">
  <div class="row justify-content-star m-0">
    <div class="col-lg-3 col-md-4 col-6 p-2" *ngFor="let object of products" routerLink="/client/product/detail/{{object.id}}" (click)="scrollTo(top)">
      <div class="col-12 m-0 p-0" style="overflow: hidden; height: 20rem; cursor: pointer; border: 1px solid #e0e0e0; background:white;">
        <div class="img-card col-12 p-0">
          <div class="bandera" *ngIf="object.discount">
            <p style="margin: 0px;">DESCUENTO {{object.discountValue}}%</p>
          </div>
          <img src="{{object.image}}" alt="{{object.name}}" class="image" routerLink="/client/product/detail/{{object.id}}">
          <button type="button"  [ngClass]="object.like ? 'boton-like':'boton-dislike'" (click)="object.like=!object.like"  [matTooltip]="object.like ? 'Quitar de favoritos':'Agregar a favoritos'"></button>
        </div>
        <div class="row justify-content-center colores" style="width: 100%; margin: 0px;">
          <ul class="color">
            <li class="color" *ngFor="let object of object.attributesList" matTooltip="{{object.wood}}">
              <div class="circulo" *ngIf="!object.isTexture" [ngStyle]="{'background': object.texture }"></div><p></p>
              <div class="circulo" *ngIf="object.isTexture"> <img src="{{object.texture}}" class="circulo" style="position:absolute;"> </div><p></p>
            </li>
          </ul>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;" routerLink="/client/product/detail/{{object.id}}">
          <p class="producto">{{object.name}}</p>
          <p class="marca">{{object.maker.name}}</p>
        </div>
        <div class="row justify-content-center" style="width: 100%; margin: 0px;">
          <h3 class="precio">{{object.price | currency:'MXN':'symbol-narrow'}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<app-client-footer></app-client-footer>
