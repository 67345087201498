<app-admin-toolbar></app-admin-toolbar>
<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Mescalero del Mes</h2>
      </div>
      <div class="col-3 text-right">
      </div>
      <p class="col-12">Ingrese los datos requeridos del formulario.</p>
    </div>

    <div class="row justify-content-center">
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Nombre.</mat-label>
        <input matInput placeholder="Agregar un nombre." name="nombre" [formControl]="complexForm.controls['name']" [(ngModel)]="object.name" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['name'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Descripción</mat-label>
        <textarea matInput placeholder="Descripción" name="descripcion" [formControl]="complexForm.controls['description']" [(ngModel)]="object.description" required></textarea>
        <mat-error *ngIf="complexForm.controls['description'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>


      <mat-form-field appearance="outline" class="col-10">
        <mat-label>Marca</mat-label>
        <mat-select name="Marca" [(ngModel)]="object.maker.id" [formControl]="complexForm.controls['maker']" required>
          <mat-option *ngFor="let object of makerList" [value]="object.id">
            {{object.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="complexForm.controls['maker'].hasError('required')">
          Campo <strong>requerido</strong>.
        </mat-error>
      </mat-form-field>
      <div class="col-10">
        <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
        <div class="cardImg">
          <span *ngIf="metadata.img.name == ''">Agregar imagen</span>
          <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>
          <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
          <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
        </div>
      </div>
      <div class="col-10 mt-3">
        <button mat-raised-button color="primary" style="width:100%;"  (click)="send(object);" [disabled]="complexForm.invalid">Guardar</button>
      </div>
    </div>


  </div>
</div>
