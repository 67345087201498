<p style="padding-top: 15px; padding-bottom: 15px; font-weight: bold;">Datos de contacto.</p>
<ul style="list-style:none; padding: 0px; font-size:10pt; color:#6f6f6f;">
  <li></li>
  <li>Cliente: {{object.name}}</li>
  <li>Teléfono: {{object.phone}}</li>
  <li>Nº Personas: {{object.email}}</li>
  <li>Fecha: {{object.meetdate | date : 'dd-MM-yyyy'}}</li>
</ul>
<div class="row justify-content-end mt-4">
  <div class="col-4">
    <button mat-flat-button color="warn" style="width:100%;"  (click)="close(true);">Cerrar</button>
    <br>
  </div>
</div>
