import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';

@Component({
  selector: 'app-admin-booking-details',
  templateUrl: './admin-booking-details.component.html',
  styleUrls: ['./admin-booking-details.component.css']
})
export class AdminBookingDetailsComponent implements OnInit {
  contactModel = {
    id:'',
    name:'',
    email:'',
    phone:'',
    meetdate:'',
    time:'',
    comments:'',
    virtual:true,
    property:{
      id:''
    },
    status:true
  };

  object = JSON.parse(JSON.stringify(this.contactModel));

  constructor(
    public session:SessionService,
    public loadding:LoadingService,
    public dialogRef: MatDialogRef<AdminBookingDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.object = this.data;
    console.log(this.data);
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'contact:001';
    }
    this.dialogRef.close(object);
  }
}
