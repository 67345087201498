import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../directive/loading/loading.module';
import { SessionService } from '../../service/session/session.module';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminSalesDetailsComponent } from './admin-sales-details/admin-sales-details.component';
import * as moment from 'moment';
moment.locale('es-us');
import { Subject } from "rxjs"
import { debounceTime, switchMap } from 'rxjs/operators'


@Component({
  selector: 'app-admin-sales',
  templateUrl: './admin-sales.component.html',
  styleUrls: ['./admin-sales.component.css']
})
export class AdminSalesComponent implements OnInit {

  saleMin = {
    status:'Pagado', //Pagado | Pendiente
    filter:'',
    max:25,
    page: 1,
    orderStatus:''
  }
  metadata = {
    searchBoxInput: new Subject<string>(),
    searching:false
  }

  saleList = [];
  paginator = {
    total:0,
    pageSizeOptions:[ 2 ,5, 10, 25, 100],
  };

  pageEvent: PageEvent;
  constructor(protected session: SessionService, public loading: LoadingService, private router: Router, private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public snackBar: MatSnackBar) {
      this.metadata.searchBoxInput.pipe(debounceTime(700),
      switchMap(val => {
        // console.log('called once',val)
        if (val != '') {
          //this.saleList = [];
        } else {
          //this.saleList = [];
          //this.saleMin.filter = '';
        }
        return val;
      })
    ).subscribe(()=>{})
  }

  ngOnInit(): void {
    this.saleMin.status = 'Pagado';
    this.saleMin.orderStatus = 'Nuevo';
    this.getSalesByOrder();
  }

  changeTabSale(ev){
    console.log(ev);
    switch(ev.tab.textLabel){
      case 'Pendiente':
        // console.log("pendiente");
        this.saleMin.status = ev.tab.textLabel;
        this.getSales();
      break;
      case 'Pagado':
      // console.log("Pagado");
      this.saleMin.status = ev.tab.textLabel;
      this.getSalesByOrder();
      break;
      default:
      break;
    }
  }

  /**funcionalidad para obtener las ventas por status
  */
  getSales(){
    this.loading.show(true,"Espere un momento");
    this.session.postRequest("sale:findAllByStatus",this.saleMin).subscribe((data:any)=>{
      this.saleList = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    });
  }

  filtaerTabBySaleAndOrderStatus(ev){
    switch(ev.tab.textLabel){
      case 'Nuevo':
      this.saleMin.orderStatus = ev.tab.textLabel;
      break;
      case 'Confirmado':
      this.saleMin.orderStatus = ev.tab.textLabel;
      break;
      case 'En camino':
      this.saleMin.orderStatus = ev.tab.textLabel;
      break;
      case 'Entregado':
      this.saleMin.orderStatus = ev.tab.textLabel;
      break;
      default:
      break;
    }
    this.getSalesByOrder();
  }

  /**funcionalidad para obtener las ventas por status y status de ordenes
  */
  getSalesByOrder(){
    this.loading.show(true,"Espere un momento");
    this.session.postRequest("sale:findAllByStatusAndStatusOrder",this.saleMin).subscribe((data:any)=>{
      this.saleList = data.object.instanceList;
      this.paginator.total = data.object.total;
      this.loading.hide();
    },error=>{
      this.loading.hide();
      console.log(error);
    });
  }

  search(){
    this.saleMin.page = 1;
    this.metadata.searchBoxInput.next(this.saleMin.filter);
  }

  // funcion para obtener los datos del paginado.
  onPaginateChange(event){
    this.saleMin.max = event.pageSize;
    this.saleMin.page = event.pageIndex + 1;
    // this.getProducts();
  }

  /**funcionalidad para ver el detalle de una ventas por status
  */
  openSale(object){
    const dialogRef = this.dialog.open(AdminSalesDetailsComponent, {
      width: '60%',
      height: '90%',
      data: object,
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined ){
        if(result.transaction == 'ok'){
          // El modal se cerro con objeto
          this.getSalesByOrder();
        }else{
          // El modal se cerro sin objeto
          this.snackBar.open('No se guardo ningun registro', '', {
            duration: 1000
          });
        }
      }else{
        // El modal se cerro sin seleccionar algo, dandole click fuera
        this.snackBar.open('No se guardo ningun registro', '', {
          duration: 1000
        });
      }
    });
  }

}
