import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { Subject } from "rxjs";
import { debounceTime, switchMap } from 'rxjs/operators';
import { StorageTool } from '../../service/session/storage.tool';
import { MatDialog } from '@angular/material/dialog';
import { ClientProductShoopingComponent } from './client-product-shooping/client-product-shooping.component';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-client-product',
  templateUrl: './client-product.component.html',
  styleUrls: ['./client-product.component.css']
})
export class ClientProductComponent implements OnInit {
  productMin = {
    status: true,
    order:'',
    max:20,
    page:1,
    category:{
      id:''
    },
    maker:{
      id:''
    },
    filter:''
  };
  list = [];
  metadata ={
    language:'spanish',
    getMoreProducts:true,
    searchBoxInput: new Subject<string>(),
  }
  imagesCategoy = [];

  categoryMin = {
    max: 100,
    page: 1,
  }
  categories = [];
  makerMin = {
    max: 200,
    page: 1,
  }
  makers = [];

  constructor(private session:SessionService, public loading:LoadingService, public storage: StorageTool, public dialog: MatDialog,
    private router:Router,
    public activatedRoute:ActivatedRoute
  ) {
    this.metadata.searchBoxInput.pipe(debounceTime(700),
      switchMap(val => {
        console.log(val);
        if (val != '') {
          this.list = [];
          // this.productMin.filter = '';
        } else {
          this.list = [];
        }
        this.getProducts();
        return val;
      })
    ).subscribe(()=>{});
  }

  async ngOnInit() {
    // await this.getCategories();
    this.activatedRoute.params.subscribe((params: Params)=>{
      if(params['maker'] == 'all'){
        this.getProducts();
        this.getmaker();
      }else{
        this.productMin.maker.id = params['maker'];
        this.getProducts();
        this.getmaker();
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /**funcionalidad para verTodos los productos
  */
  showAllProducts(){
    this.metadata.getMoreProducts = true;
    for(let i = 0; i < this.makers.length; i++){
      this.makers[i].select = false
    }
    this.productMin.page = 1;
    this.metadata.getMoreProducts = true;
    this.productMin.maker.id = "";
    this.list = [];
    this.getProducts();
  }

  /**funcionalidad para ver productos por marcas
  */
  findProductByMaker(object){
    this.productMin.page = 1;
    this.metadata.getMoreProducts = true;
    for(let i = 0; i < this.makers.length; i++){
      this.makers[i].select = false
    }
    this.list = [];
    object.select = true;
    this.productMin.maker.id = object.id;
    this.getProducts();
  }

  /**función para obtener el listado de categorias
  */
  getmaker(){
    this.session.postRequest("maker:list",this.makerMin).subscribe((data:any)=>{
      this.makers = data.object.instanceList.sort(function(a, b) {
        return a.id - b.id;
      });
      for(let i = 0; i < this.makers.length; i++){
        if(this.productMin.maker.id ==  this.makers[i].id){
          this.makers[i].select = true;
        }
      }
      // if(this.makers.length > 0){
      //   this.findProductByMaker(this.makers[0]);
      // }
    },error=>{
      console.log("error:maker:list",error);
    })
  }

  /**funcionalidad para obtener el lsuatdo de productos
  */
  getProducts(){
    this.loading.show(true,"Espere un momento...")
    if(this.productMin.filter == ''){
      if(this.productMin.maker.id == ''){
        this.session.postRequest("product:findAllByStatus",this.productMin).subscribe((data:any)=>{
          for(let i = 0; i < data.object.instanceList.length; i++){
            data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
            data.object.instanceList[i].like=false;
          }
          this.list = this.list.concat(data.object.instanceList);
          if(data.object.instanceList.length == 0){
            this.metadata.getMoreProducts = false;
          }else{
            this.metadata.getMoreProducts = true;
          }
          for(let i = 0; i < this.list.length; i++){
            // this.getAttributes(this.list[i])
          }
          this.loading.hide();
        },error=>{
          console.log("error:product:findAllByStatus",error);
          this.loading.hide();
        })
      }else{
        this.session.postRequest("product:findAllByMaker",this.productMin).subscribe((data:any)=>{
          for(let i = 0; i < data.object.instanceList.length; i++){
            data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
            data.object.instanceList[i].like=false;
          }
          this.list = this.list.concat(data.object.instanceList);
          if(data.object.instanceList.length == 0){
            this.metadata.getMoreProducts = false;
          }else{
            this.metadata.getMoreProducts = true;
          }
          for(let i = 0; i < this.list.length; i++){
            // this.getAttributes(this.list[i])
          }
          this.loading.hide();
        },error=>{
          console.log("error:product:findAllByStatus",error);
          this.loading.hide();
        })
      }

    }else{
      this.session.postRequest("product:searching",this.productMin).subscribe((data:any)=>{
        for(let i = 0; i < data.object.instanceList.length; i++){
          data.object.instanceList[i].image = this.session.urlIMG+'uploads/'+data.object.instanceList[i].image;
          data.object.instanceList[i].like=false;
        }
        this.list = this.list.concat(data.object.instanceList);
        if(data.object.instanceList.length == 0){
          this.metadata.getMoreProducts = false;
        }else{
          this.metadata.getMoreProducts = true;
        }
        for(let i = 0; i < this.list.length; i++){
          // this.getAttributes(this.list[i])
        }
        this.loading.hide();
      },error=>{
        console.log("error:product:list",error);
        this.loading.hide();
      })
    }
  }


  /**función para obetner mas productos
  */
  showMoreProducts(){
    if(this.metadata.getMoreProducts){
      this.productMin.page++;
      this.metadata.getMoreProducts = false;
      this.getProducts();
    }
  }

  /**función para obtener el listado de categorias
  */
  getCategories(){
    return new Promise((resolve,reject)=>{
      this.session.postRequestAnonimus("category:list",this.categoryMin).subscribe(async(data:any)=>{
        this.categories = data.object.instanceList;
        if(this.categories.length > 0)
          this.productMin.category.id = this.categories[0].id;
        resolve(true);
      },error=>{
        console.log("error:category:list",error);
        reject(error);
      })
    })
  }

  changeCategory(ev){
    this.list = [];
    this.productMin.category.id =  this.categories[ev.index].id;
    this.productMin.page = 1;
    this.metadata.getMoreProducts = true;
    this.getProducts();

  }

  search(){
    this.productMin.page = 1;
    this.metadata.searchBoxInput.next(this.productMin.filter);
  }

  /**funcionalidad para agregar un articulo al carrito.
  */
  addToShoppingCard(object){
    this.storage.getAkeyFromStorage('shoppingCart').then((data:any)=>{
      let shoppingCart = [];
      if(data){
        shoppingCart = data;
      }
      // let productPriceAux:any = {};
      // if(object.product_prices.length == 0){
      // }else{
      //   productPriceAux = object.product_prices[0];
      //   object.price = productPriceAux.price;
      // }
      // this.object.attributesList = [];
      shoppingCart.push({
        quantity:1,
        product:object,
        productPrice:object.price
      });
      localStorage.setItem('carritoLength', shoppingCart.length.toString());
      // object.image = 'https://casamexcalli.com/public/uploads/'+object.image;
      this.storage.saveAKeyOnTheStorage('shoppingCart',shoppingCart).then(()=>{
        // this.session.setShoppingCartTotal(shoppingCart.length);

        const dialogRef = this.dialog.open(ClientProductShoopingComponent , {
          width: '50%',
          data: object,
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result != undefined ){
            if(result.transaction == 'ok'){
              // El modal se cerro con objeto
            }else{
              // El modal se cerro sin objeto
              // this.snackBar.open('No se guardo ningun registro', '', {
              //   duration: 1000
              // });
            }
          }else{
            // El modal se cerro sin seleccionar algo, dandole click fuera
            // this.snackBar.open('No se guardo ningun registro', '', {
            //   duration: 1000
            // });
          }
        });

      }).catch(e=>{
        console.log(e);
      })

    }).catch(e=>{
      console.log(e);
    })
  }

}
