import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SessionService } from '../../service/session/session.module';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.service';
import { StorageTool } from '../../service/session/storage.tool';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.css']
})
export class AdminLoginComponent implements OnInit {

  object = {
    email:"",
    password:"",
    remember_me:true
  }

  complexForm: FormGroup;

    constructor(public fb: FormBuilder,
      private session: SessionService,
      private snackBar: MatSnackBar,
      public loadingService:LoadingService,
      private router:Router,
      private storage:StorageTool
    ) {
      this.complexForm = fb.group({
        // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
        'username': [null, Validators.required],
        'password': [null, Validators.compose([Validators.required])],
      });
    }


  ngOnInit(): void {
    this.storage.getAkeyFromStorage("object").then((data:any)=>{
      if(data){
        this.object = data;
      }
    })
  }

  // metod para realizar Login
  send() {
    this.loadingService.show(true,'Iniciando sesion');
    // Mandamos a invocar el servicio de login
    this.session.login(this.object).then((data:any)=> {
      if (data.transaction === 'ok') {
        this.snackBar.open(data.message, 'Aceptar', {duration: 5000});
        if(this.object.remember_me){
          this.storage.saveAKeyOnTheStorage("object",this.object);
        }
        this.loadingService.hide();
        //logica para determinar a donde se loguea el usuario
        let role = this.session.getRoles();
        switch(role[0]){
          case 'ADMINISTRATOR':
            this.router.navigate(['/admin/sales']);
            this.snackBar.open('Bienvenido', 'Aceptar', {duration: 5000});
          break;
          default:
            this.snackBar.open('Lamentablemente aun no tenemos un acceso para tu usuario...', 'Error', {duration: 5000});
          break;
        }
      } else {
        this.loadingService.hide();
        this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
        console.log('Usuario o Contraseña incorrectos')
      }
    }).catch((error)=>{
      this.loadingService.hide();
      this.snackBar.open('Usuario ó Contraseña incorrectos', 'Error', {duration: 5000});
      console.log('Error: ', error);
    });
  }


}
